
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n707Child = []
  var n708Child = []
  var n709Child = []
  n709Child.push("狀態名稱")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n709 = h('label', props, n709Child)
  n708Child.push(n709)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n708 = h('div', props, n708Child)
  n707Child.push(n708)
  var n710Child = []
  if(shadow.names.indexOf("") == -1) {
    shadow.names.push("")
  }
  var v711 = shadow.names
  Object.keys(v711).forEach(function (index) {
    var value = v711[index]
    var n712Child = []
    var n713Child = []
    var n714Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'type', val: "text"},{name:'name', val: `names[${index}]`},{name:'value', val: value}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n714 = h('input', props, n714Child)
    n713Child.push(n714)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n713 = h('div', props, n713Child)
    n712Child.push(n713)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'key', val: `names[${index}]`}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n712 = h('div', props, n712Child)
    n710Child.push(n712)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n710 = h('div', props, n710Child)
  n707Child.push(n710)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n707 = h('div', props, n707Child)
  n0Child.push(n707)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  