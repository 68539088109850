
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  renderred.push(scene)
  var n10Child = []
  var n11Child = []
  var n12Child = []
  var n13Child = []
  n13Child = n13Child.concat(scene._name || "(未命名)")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'card-header-title'},{name:'class', val: 'name'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n13 = h('div', props, n13Child)
  n12Child.push(n13)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'card-header'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n12 = h('header', props, n12Child)
  n11Child.push(n12)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'scene'},{name:'class', val: 'card'},{name:'onclick', val: this._sceneClicked.bind(this, scene)},{name:'key', val: scene}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n11 = h('div', props, n11Child)
  n10Child.push(n11)
  var n14Child = []
  var v15 = this._getRelatedScenes(scene)
  Object.keys(v15).forEach(function (k16) {
    var scene = v15[k16]
    if(renderred.indexOf(scene) == -1) {
      n14Child = n14Child.concat(renderSceneInFlow.call(this, {scene: scene, renderSceneInFlow: renderSceneInFlow, renderred: renderred}))
    }
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'related-scenes'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n14 = h('div', props, n14Child)
  n10Child.push(n14)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'scene-in-flow'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n10 = h('div', props, n10Child)
  n0Child.push(n10)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  