
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n550Child = []
  var n551Child = []
  var n552Child = []
  n552Child.push("替換對象")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n552 = h('label', props, n552Child)
  n551Child.push(n552)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n551 = h('div', props, n551Child)
  n550Child.push(n551)
  var n553Child = []
  var n554Child = []
  var n555Child = []
  var n556Child = []
  var n557Child = []
  n557Child.push("主場景")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "main"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n557 = h('option', props, n557Child)
  n556Child.push(n557)
  var n558Child = []
  n558Child.push("主場景，同時替換背景場景為原主場景")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "shift"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n558 = h('option', props, n558Child)
  n556Child.push(n558)
  var n559Child = []
  n559Child.push("背景場景")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "background"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n559 = h('option', props, n559Child)
  n556Child.push(n559)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "target"},{name:'value', val: shadow.target}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n556 = h('select', props, n556Child)
  n555Child.push(n556)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n555 = h('div', props, n555Child)
  n554Child.push(n555)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n554 = h('div', props, n554Child)
  n553Child.push(n554)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n553 = h('div', props, n553Child)
  n550Child.push(n553)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n550 = h('div', props, n550Child)
  n0Child.push(n550)
  var n560Child = []
  var n561Child = []
  var n562Child = []
  n562Child.push("場景 ID")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n562 = h('label', props, n562Child)
  n561Child.push(n562)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n561 = h('div', props, n561Child)
  n560Child.push(n561)
  var n563Child = []
  var n564Child = []
  var n565Child = []
  var n566Child = []
  var v567 = this.playbookEditor.scenes
  Object.keys(v567).forEach(function (k568) {
    var scene = v567[k568]
    var n569Child = []
    n569Child = n569Child.concat(scene._name || "(未命名)")
    var props = {attributes: runtime.compileAttrs([{name:'key', val: scene},{name:'value', val: scene.id},{name:'selected', val: (shadow.sceneId == scene.id)}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n569 = h('option', props, n569Child)
    n566Child.push(n569)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "sceneId"},{name:'value', val: shadow.sceneId}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n566 = h('select', props, n566Child)
  n565Child.push(n566)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n565 = h('div', props, n565Child)
  n564Child.push(n565)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n564 = h('div', props, n564Child)
  n563Child.push(n564)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n563 = h('div', props, n563Child)
  n560Child.push(n563)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n560 = h('div', props, n560Child)
  n0Child.push(n560)
  var n570Child = []
  var n571Child = []
  var n572Child = []
  n572Child.push("轉場特效")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n572 = h('label', props, n572Child)
  n571Child.push(n572)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n571 = h('div', props, n571Child)
  n570Child.push(n571)
  var n573Child = []
  var n574Child = []
  var n575Child = []
  var n576Child = []
  var n577Child = []
  n577Child.push("無")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: ""}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n577 = h('option', props, n577Child)
  n576Child.push(n577)
  var n578Child = []
  n578Child.push("淡入淡出")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "crossfade"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n578 = h('option', props, n578Child)
  n576Child.push(n578)
  var n579Child = []
  n579Child.push("翻頁")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "turn-page"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n579 = h('option', props, n579Child)
  n576Child.push(n579)
  var n580Child = []
  n580Child.push("向下擦入")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "slide-down"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n580 = h('option', props, n580Child)
  n576Child.push(n580)
  var n581Child = []
  n581Child.push("向上擦入")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "slide-up"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n581 = h('option', props, n581Child)
  n576Child.push(n581)
  var n582Child = []
  n582Child.push("向右擦入")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "slide-right"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n582 = h('option', props, n582Child)
  n576Child.push(n582)
  var n583Child = []
  n583Child.push("向左擦入")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "slide-left"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n583 = h('option', props, n583Child)
  n576Child.push(n583)
  var n584Child = []
  n584Child.push("水平打開")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "horizontal-open"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n584 = h('option', props, n584Child)
  n576Child.push(n584)
  var n585Child = []
  n585Child.push("水平關閉")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "horizontal-close"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n585 = h('option', props, n585Child)
  n576Child.push(n585)
  var n586Child = []
  n586Child.push("垂直打開")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "vertical-open"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n586 = h('option', props, n586Child)
  n576Child.push(n586)
  var n587Child = []
  n587Child.push("垂直關閉")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "vertical-close"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n587 = h('option', props, n587Child)
  n576Child.push(n587)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "transition"},{name:'value', val: shadow.transition}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n576 = h('select', props, n576Child)
  n575Child.push(n576)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n575 = h('div', props, n575Child)
  n574Child.push(n575)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n574 = h('div', props, n574Child)
  n573Child.push(n574)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n573 = h('div', props, n573Child)
  n570Child.push(n573)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n570 = h('div', props, n570Child)
  n0Child.push(n570)
  var n588Child = []
  var n589Child = []
  var n590Child = []
  n590Child.push("轉場秒數")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n590 = h('label', props, n590Child)
  n589Child.push(n590)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n589 = h('div', props, n589Child)
  n588Child.push(n589)
  var n591Child = []
  var n592Child = []
  var n593Child = []
  var n594Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "transitionDuration"},{name:'value', val: shadow.transitionDuration},{name:'type', val: "number"},{name:'step', val: "0.1"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n594 = h('input', props, n594Child)
  n593Child.push(n594)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n593 = h('div', props, n593Child)
  n592Child.push(n593)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n592 = h('div', props, n592Child)
  n591Child.push(n592)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n591 = h('div', props, n591Child)
  n588Child.push(n591)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n588 = h('div', props, n588Child)
  n0Child.push(n588)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  