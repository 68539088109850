
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n17Child = []
  n17Child.push("新增場景")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-primary'},{name:'href', val: "javascript:void(0)"},{name:'onclick', val: this.addScene}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n17 = h('a', props, n17Child)
  n0Child.push(n17)
  var n18Child = []
  n18Child.push("編輯背景場景")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-secondary'},{name:'href', val: "javascript:void(0)"},{name:'onclick', val: this.editBackgroundScene}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n18 = h('a', props, n18Child)
  n0Child.push(n18)
  var n19Child = []
  n19Child.push("編輯非公開期間場景")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-secondary'},{name:'href', val: "javascript:void(0)"},{name:'onclick', val: this.editLandingScene}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n19 = h('a', props, n19Child)
  n0Child.push(n19)
  var v20 = this.pushEventTriggers
  Object.keys(v20).forEach(function (k21) {
    var trigger = v20[k21]
    var n22Child = []
    n22Child.push("觸發：")
    n22Child = n22Child.concat(trigger._name)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-link'},{name:'key', val: trigger},{name:'href', val: "javascript:void(0)"},{name:'onclick', val: this.triggerPushEvent.bind(this, trigger.id)}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n22 = h('a', props, n22Child)
    n0Child.push(n22)
  }.bind(this))
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  