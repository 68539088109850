import Base from './base'
import renderFields from './notification.pug'

export default class Notification extends Base
  @label: "系統通知"

  @DEFAULTS:
    title: ""
    text: ""
    instanceId: null
    delay:
      seconds: 0

  renderFields: renderFields
