
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n723Child = []
  var n724Child = []
  var n725Child = []
  n725Child.push("識別標籤")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n725 = h('label', props, n725Child)
  n724Child.push(n725)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n724 = h('div', props, n724Child)
  n723Child.push(n724)
  var n726Child = []
  var n727Child = []
  var n728Child = []
  var n729Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "instanceId"},{name:'value', val: shadow.instanceId},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n729 = h('input', props, n729Child)
  n728Child.push(n729)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n728 = h('div', props, n728Child)
  n727Child.push(n728)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n727 = h('div', props, n727Child)
  n726Child.push(n727)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n726 = h('div', props, n726Child)
  n723Child.push(n726)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n723 = h('div', props, n723Child)
  n0Child.push(n723)
  var n730Child = []
  var n731Child = []
  var n732Child = []
  n732Child.push("通知標題")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n732 = h('label', props, n732Child)
  n731Child.push(n732)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n731 = h('div', props, n731Child)
  n730Child.push(n731)
  var n733Child = []
  var n734Child = []
  var n735Child = []
  var n736Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "title"},{name:'value', val: shadow.title},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n736 = h('input', props, n736Child)
  n735Child.push(n736)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n735 = h('div', props, n735Child)
  n734Child.push(n735)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n734 = h('div', props, n734Child)
  n733Child.push(n734)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n733 = h('div', props, n733Child)
  n730Child.push(n733)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n730 = h('div', props, n730Child)
  n0Child.push(n730)
  var n737Child = []
  var n738Child = []
  var n739Child = []
  n739Child.push("通知本文")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n739 = h('label', props, n739Child)
  n738Child.push(n739)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n738 = h('div', props, n738Child)
  n737Child.push(n738)
  var n740Child = []
  var n741Child = []
  var n742Child = []
  var n743Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "text"},{name:'value', val: shadow.text},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n743 = h('input', props, n743Child)
  n742Child.push(n743)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n742 = h('div', props, n742Child)
  n741Child.push(n742)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n741 = h('div', props, n741Child)
  n740Child.push(n741)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n740 = h('div', props, n740Child)
  n737Child.push(n740)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n737 = h('div', props, n737Child)
  n0Child.push(n737)
  var n744Child = []
  var n745Child = []
  var n746Child = []
  n746Child.push("延遲基礎時間，留空 = 執行時間")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n746 = h('label', props, n746Child)
  n745Child.push(n746)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n745 = h('div', props, n745Child)
  n744Child.push(n745)
  var n747Child = []
  var n748Child = []
  var n749Child = []
  var n750Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "delay[epoch]"},{name:'value', val: shadow.delay.epoch},{name:'type', val: "number"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n750 = h('input', props, n750Child)
  n749Child.push(n750)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n749 = h('div', props, n749Child)
  n748Child.push(n749)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n748 = h('div', props, n748Child)
  n747Child.push(n748)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n747 = h('div', props, n747Child)
  n744Child.push(n747)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n744 = h('div', props, n744Child)
  n0Child.push(n744)
  var n751Child = []
  var n752Child = []
  var n753Child = []
  n753Child.push("延遲秒數")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n753 = h('label', props, n753Child)
  n752Child.push(n753)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n752 = h('div', props, n752Child)
  n751Child.push(n752)
  var n754Child = []
  var n755Child = []
  var n756Child = []
  var n757Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "delay[seconds]"},{name:'value', val: shadow.delay.seconds},{name:'type', val: "number"},{name:'step', val: "0.01"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n757 = h('input', props, n757Child)
  n756Child.push(n757)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n756 = h('div', props, n756Child)
  n755Child.push(n756)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n755 = h('div', props, n755Child)
  n754Child.push(n755)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n754 = h('div', props, n754Child)
  n751Child.push(n754)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n751 = h('div', props, n751Child)
  n0Child.push(n751)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  