
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n822Child = []
  var n823Child = []
  var n824Child = []
  n824Child.push("秒數 (0=無限)")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n824 = h('label', props, n824Child)
  n823Child.push(n824)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n823 = h('div', props, n823Child)
  n822Child.push(n823)
  var n825Child = []
  var n826Child = []
  var n827Child = []
  var n828Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "seconds"},{name:'value', val: shadow.seconds},{name:'type', val: "number"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n828 = h('input', props, n828Child)
  n827Child.push(n828)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n827 = h('div', props, n827Child)
  n826Child.push(n827)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n826 = h('div', props, n826Child)
  n825Child.push(n826)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n825 = h('div', props, n825Child)
  n822Child.push(n825)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n822 = h('div', props, n822Child)
  n0Child.push(n822)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  