
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n480Child = []
  var n481Child = []
  n481Child.push("經度")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n481 = h('label', props, n481Child)
  n480Child.push(n481)
  var n482Child = []
  var n483Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "longitude"},{name:'value', val: shadow.longitude},{name:'type', val: "number"},{name:'step', val: "0.000000001"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n483 = h('input', props, n483Child)
  n482Child.push(n483)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n482 = h('div', props, n482Child)
  n480Child.push(n482)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n480 = h('div', props, n480Child)
  n0Child.push(n480)
  var n484Child = []
  var n485Child = []
  n485Child.push("緯度")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n485 = h('label', props, n485Child)
  n484Child.push(n485)
  var n486Child = []
  var n487Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "latitude"},{name:'value', val: shadow.latitude},{name:'type', val: "number"},{name:'step', val: "0.000000001"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n487 = h('input', props, n487Child)
  n486Child.push(n487)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n486 = h('div', props, n486Child)
  n484Child.push(n486)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n484 = h('div', props, n484Child)
  n0Child.push(n484)
  var n488Child = []
  var n489Child = []
  n489Child.push("距離")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n489 = h('label', props, n489Child)
  n488Child.push(n489)
  var n490Child = []
  var n491Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "distance"},{name:'value', val: shadow.distance},{name:'type', val: "number"},{name:'step', val: "0.01"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n491 = h('input', props, n491Child)
  n490Child.push(n491)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n490 = h('div', props, n490Child)
  n488Child.push(n490)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n488 = h('div', props, n488Child)
  n0Child.push(n488)
  var n492Child = []
  var n493Child = []
  n493Child.push("比較方式")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n493 = h('label', props, n493Child)
  n492Child.push(n493)
  var n494Child = []
  var n495Child = []
  var n496Child = []
  n496Child.push("小於")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "<"},{name:'selected', val: (shadow.operator == "<")}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n496 = h('option', props, n496Child)
  n495Child.push(n496)
  var n497Child = []
  n497Child.push("大於")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: ">"},{name:'selected', val: (shadow.operator == ">")}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n497 = h('option', props, n497Child)
  n495Child.push(n497)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "operator"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n495 = h('select', props, n495Child)
  n494Child.push(n495)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n494 = h('div', props, n494Child)
  n492Child.push(n494)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n492 = h('div', props, n492Child)
  n0Child.push(n492)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  