
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n466Child = []
  var n467Child = []
  var n468Child = []
  n468Child.push("基礎時間，留空 = 執行當下")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n468 = h('label', props, n468Child)
  n467Child.push(n468)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n467 = h('div', props, n467Child)
  n466Child.push(n467)
  var n469Child = []
  var n470Child = []
  var n471Child = []
  var n472Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "epoch"},{name:'value', val: shadow.epoch},{name:'type', val: "number"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n472 = h('input', props, n472Child)
  n471Child.push(n472)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n471 = h('div', props, n471Child)
  n470Child.push(n471)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n470 = h('div', props, n470Child)
  n469Child.push(n470)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n469 = h('div', props, n469Child)
  n466Child.push(n469)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n466 = h('div', props, n466Child)
  n0Child.push(n466)
  var n473Child = []
  var n474Child = []
  var n475Child = []
  n475Child.push("延遲秒數")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n475 = h('label', props, n475Child)
  n474Child.push(n475)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n474 = h('div', props, n474Child)
  n473Child.push(n474)
  var n476Child = []
  var n477Child = []
  var n478Child = []
  var n479Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "seconds"},{name:'value', val: shadow.seconds},{name:'type', val: "number"},{name:'step', val: "0.01"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n479 = h('input', props, n479Child)
  n478Child.push(n479)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n478 = h('div', props, n478Child)
  n477Child.push(n478)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n477 = h('div', props, n477Child)
  n476Child.push(n477)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n476 = h('div', props, n476Child)
  n473Child.push(n476)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n473 = h('div', props, n473Child)
  n0Child.push(n473)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  