import Base from './base'
import renderFields from './flag.pug'

export default class Flag extends Base
  @label: "標記全域狀態"

  @DEFAULTS:
    names: []

  renderFields: renderFields

  onSave: (newData)->
    newData.names = newData.names.filter((s) => s.length)
