import Base from './base'
import renderFields from './goto-scene.pug'

export default class GotoScene extends Base
  @label: "切換場景"

  @DEFAULTS:
    target: "main"
    sceneId: null
    reuse: false
    transition: null
    transitionDuration: 1.0

  renderFields: renderFields
