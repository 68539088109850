import Base from './base'
import renderFields from './enable-torch.pug'

export default class EnableTorch extends Base
  @label: "開手電筒"

  @DEFAULTS:
    seconds: 0

  renderFields: renderFields
