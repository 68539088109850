import './_bulma.scss'
import '@fortawesome/fontawesome-free/css/all.css'

import Turbolinks from 'turbolinks'
import RailsUjs from '@rails/ujs'
import 'pug-vdom/runtime'
import '@fortawesome/fontawesome-free/js/all.js'
import "trix"
import "@rails/actiontext"

import 'common'
import PlaybookEditor from 'playbook-editor'

# disable FA ads
window.FontAwesome.config.keepOriginalSource = false

Turbolinks.start()
RailsUjs.start()

document.addEventListener "turbolinks:load", ->
  return unless at("playbooks#edit")

  PlaybookEditor.init()
