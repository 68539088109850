import { h, createProjector } from 'maquette'
import { cloneDeep } from 'lodash-es'

import { v4 as uuid } from 'uuid'

import templateHelpers from './template-helpers'
import render from './scene-editor.pug'
import TRIGGERS from './triggers'
import ACTIONS, { checkConflict as checkActionConflict } from './actions'

export default class SceneEditor
  @TRIGGERS: TRIGGERS
  @ACTIONS: ACTIONS

  @DEFAULT_DISPLAY = { type: "webview" }

  @AUDIO_TRACK_INT_FIELDS = ["assetId", "instanceId", "repeat"]
  @AUDIO_TRACK_FLOAT_FIELDS = ["currentTime", "fadeInSeconds", "fadeInSeconds", "repeat"]

  @AUDIO_TRACK_DEFAULTS:
    assetId: null
    instanceId: null
    pause: false,
    currentTime: 0
    fadeInSeconds: 0
    fadeOutSeconds: 0
    volume: 1.0
    repeat: 1

  @property "assetsManager", get: -> @playbookEditor.assetsManager

  @property "activeActionSet", get: ->
    if @activeTrigger then @activeTrigger.actions else @scene.initial

  @property "displayAsset", get: -> @assetsManager.assetFor(@activeActionSet.display)

  @property "triggersMenu", get: ->
    items = [{ trigger: null, actions: @scene.initial, label: '初始狀態'}]

    for trigger in @scene.triggers
      label = @constructor.TRIGGERS[trigger.type].label || trigger.type

      items.push(
        trigger: trigger,
        actions: trigger.actions,
        label: "#{label}: #{trigger._name || '(未命名)'}"
      )

    items

  @property "addActionsMenu", get: ->
    compatible = []
    incompatible = []

    for actionType, definition of ACTIONS
      continue if @activeActionSet[actionType]

      conflict =  checkActionConflict(Object.keys(@activeActionSet), actionType)
      menuToAdd = if conflict then incompatible else compatible

      menuToAdd.push({
        type: actionType,
        conflict: conflict,
        ...definition
      })

    compatible.concat incompatible

  @property "actionsMenu", get: ->
    menu = []

    for actionType, definition of @constructor.ACTIONS
      continue unless @activeActionSet[actionType]
      menu.push({type: actionType, ...definition})

    menu

  editingActiveTrigger: false
  projector: createProjector()

  constructor: ({@scene, @playbookEditor})->
    @scene.initial ||= {}
    @scene.initial.display ||= Object.assign({}, @constructor.DEFAULT_DISPLAY)
    @scene.triggers ||= []

    @selectTrigger(null)
    @show()

  # null: initial state
  selectTrigger: (@activeTrigger)->
    @activeTriggerForm =
      if @activeTrigger
        new TRIGGERS[@activeTrigger.type](@activeTrigger, sceneEditor: this)
      else
        null

    @selectAction @actionsMenu[0]?.type

  deleteTrigger: (trigger)=>
    return unless confirm("確認刪除觸發條件: #{trigger.label}")

    @scene.triggers.splice(@scene.triggers.indexOf(trigger), 1)
    @selectTrigger(null) if trigger == @activeTrigger

  deleteAction: (type)=>
    return unless confirm("真的要刪除 #{ACTIONS[type].label} ?")

    delete @activeActionSet[type]
    @selectAction(null) if @activeActionType == type

  selectAction: (@activeActionType)->
    @activeActionForm?.save()

    if @activeActionType
      if ACTIONS[@activeActionType]
        actionStore = @activeActionSet[@activeActionType] ||= {}
        @activeActionForm = new ACTIONS[@activeActionType](actionStore, sceneEditor: this)
      else
        console.error("Action type not supported: #{@activeActionType}")
        @selectAction null

    else
      @activeActionForm = null

  show: =>
    document.documentElement.classList.add "is-clipped"
    @projector.append(document.body, @_render)

  addTrigger: (triggerClass, e)=>
    newTrigger = triggerClass.scaffold()
    @scene.triggers.push(newTrigger)

    @selectTrigger newTrigger
    @editingActiveTrigger = true

    e.target.blur() if e

  addAction: (menuEntry, e) =>
    @selectAction(menuEntry.type)

    e.target.blur() if e

  editActiveTrigger: =>
    @editingActiveTrigger = true

  onSceneNameInput: (e)=>
    @scene._name = e.target.value

  save: ->
    await @activeActionForm?.save()
    await @activeTriggerForm?.save()
    await @assetsManager.upload()

    @playbookEditor.save()

  dismiss: =>
    await @save()

    projection = @projector.detach(@_render)
    projection.domNode.remove()
    document.documentElement.classList.remove "is-clipped"

  delete: =>
    if @scene in @playbookEditor.usedScenes
      return unless confirm("有其他場景會跳轉到這個場景，刪除後將無法正常執行，真的要刪除?")
    else
      return unless confirm("這個操作無法復原，確定要刪除這個場景?")

    @playbookEditor.scenes.splice(@playbookEditor.scenes.indexOf(@scene), 1)
    @dismiss()

  promptCopy: =>
    return unless copies = prompt("要複製幾份?", "1")

    await @activeActionForm?.save()
    await @activeTriggerForm?.save()
    await @assetsManager.upload()

    copies = parseInt(copies, 10)

    for _i in [1..copies]
      copy = cloneDeep(@scene)
      copy.id = uuid()

      for trigger in copy.triggers
        trigger._ref = uuid()

      @playbookEditor.scenes.push(copy)

    @playbookEditor.redraw()

  _render: =>
    render.call(this, templateHelpers)[0]
