
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n595Child = []
  var n596Child = []
  var n597Child = []
  n597Child.push("播放方式")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n597 = h('label', props, n597Child)
  n596Child.push(n597)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n596 = h('div', props, n596Child)
  n595Child.push(n596)
  var n598Child = []
  var n599Child = []
  var n600Child = []
  var n601Child = []
  var n602Child = []
  n602Child.push("追加 / 調整設定")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "update"},{name:'selected', val: (shadow.method != "replace")}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n602 = h('option', props, n602Child)
  n601Child.push(n602)
  var n603Child = []
  n603Child.push("替換")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "replace"},{name:'selected', val: (shadow.method == "replace")}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n603 = h('option', props, n603Child)
  n601Child.push(n603)
  var props = {attributes: runtime.compileAttrs([{name:'name', val: "method"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n601 = h('select', props, n601Child)
  n600Child.push(n601)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n600 = h('div', props, n600Child)
  n599Child.push(n600)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n599 = h('div', props, n599Child)
  n598Child.push(n599)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n598 = h('div', props, n598Child)
  n595Child.push(n598)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n595 = h('div', props, n595Child)
  n0Child.push(n595)
  var v604 = shadow.tracks
  Object.keys(v604).forEach(function (index) {
    var track = v604[index]
    var n605Child = []
    var n606Child = []
    n606Child.push("刪除音軌")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'class', val: 'is-danger'},{name:'type', val: "button"},{name:'onclick', val: this.deleteTrack.bind(this, track)}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n606 = h('button', props, n606Child)
    n605Child.push(n606)
    var n607Child = []
    var n608Child = []
    var n609Child = []
    n609Child.push("音源檔案")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n609 = h('label', props, n609Child)
    n608Child.push(n609)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n608 = h('div', props, n608Child)
    n607Child.push(n608)
    var n610Child = []
    var n611Child = []
    var n612Child = []
    var n613Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][assetId]`},{name:'value', val: track.assetId}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n613 = h('input', props, n613Child)
    n612Child.push(n613)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n612 = h('div', props, n612Child)
    n611Child.push(n612)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n611 = h('div', props, n611Child)
    n610Child.push(n611)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n610 = h('div', props, n610Child)
    n607Child.push(n610)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n607 = h('div', props, n607Child)
    n605Child.push(n607)
    var n614Child = []
    var n615Child = []
    var n616Child = []
    n616Child.push("識別標籤")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n616 = h('label', props, n616Child)
    n615Child.push(n616)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n615 = h('div', props, n615Child)
    n614Child.push(n615)
    var n617Child = []
    var n618Child = []
    var n619Child = []
    var n620Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][instanceId]`},{name:'value', val: track.instanceId}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n620 = h('input', props, n620Child)
    n619Child.push(n620)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n619 = h('div', props, n619Child)
    n618Child.push(n619)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n618 = h('div', props, n618Child)
    n617Child.push(n618)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n617 = h('div', props, n617Child)
    n614Child.push(n617)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n614 = h('div', props, n614Child)
    n605Child.push(n614)
    var n621Child = []
    var n622Child = []
    var n623Child = []
    n623Child.push("播放次數")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n623 = h('label', props, n623Child)
    n622Child.push(n623)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n622 = h('div', props, n622Child)
    n621Child.push(n622)
    var n624Child = []
    var n625Child = []
    var n626Child = []
    if(track.repeat == -1) {
      var n627Child = []
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: null},{name:'type', val: "text"},{name:'value', val: "無限"},{name:'readonly', val: true}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n627 = h('input', props, n627Child)
      n626Child.push(n627)
    } else {
      var n628Child = []
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][repeat]`},{name:'value', val: track.repeat},{name:'type', val: "number"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n628 = h('input', props, n628Child)
      n626Child.push(n628)
    }
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n626 = h('div', props, n626Child)
    n625Child.push(n626)
    var n629Child = []
    if(track.repeat == -1) {
      var n630Child = []
      n630Child.push("指定次數")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-primary'},{name:'onclick', val: this.setTrackRepeat.bind(this, track, 1)}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n630 = h('a', props, n630Child)
      n629Child.push(n630)
    } else {
      var n631Child = []
      n631Child.push("無限")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-danger'},{name:'onclick', val: this.setTrackRepeat.bind(this, track, -1)}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n631 = h('a', props, n631Child)
      n629Child.push(n631)
    }
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n629 = h('div', props, n629Child)
    n625Child.push(n629)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'has-addons'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n625 = h('div', props, n625Child)
    n624Child.push(n625)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n624 = h('div', props, n624Child)
    n621Child.push(n624)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n621 = h('div', props, n621Child)
    n605Child.push(n621)
    var n632Child = []
    var n633Child = []
    var n634Child = []
    n634Child.push("音量")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n634 = h('label', props, n634Child)
    n633Child.push(n634)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n633 = h('div', props, n633Child)
    n632Child.push(n633)
    var n635Child = []
    var n636Child = []
    if('volume' in track) {
      var n637Child = []
      n637Child.push("不變更音量")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'onclick', val: futureMerge(track, { volume: UNSET })}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n637 = h('a', props, n637Child)
      n636Child.push(n637)
      var n638Child = []
      var props = {attributes: runtime.compileAttrs([], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n638 = h('br', props, n638Child)
      n636Child.push(n638)
      if(typeof track.volume == "object") {
        var n639Child = []
        n639Child.push("改為固定音量")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'type', val: "button"},{name:'onclick', val: futureMerge(track, {volume: 1})}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n639 = h('button', props, n639Child)
        n636Child.push(n639)
      } else {
        var n640Child = []
        n640Child.push("改為 GPS 音量")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'type', val: "button"},{name:'onclick', val: futureMerge(track, {volume: {}})}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n640 = h('button', props, n640Child)
        n636Child.push(n640)
      }
    } else {
      var n641Child = []
      n641Child.push("設定音量")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'onclick', val: futureMerge(track, { volume: 1 })}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n641 = h('a', props, n641Child)
      n636Child.push(n641)
    }
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'},{name:'key', val: "buttons"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n636 = h('div', props, n636Child)
    n635Child.push(n636)
    var n642Child = []
    if(typeof track.volume == "object") {
      var n643Child = []
      var n644Child = []
      var n645Child = []
      n645Child.push("經度")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n645 = h('label', props, n645Child)
      n644Child.push(n645)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n644 = h('div', props, n644Child)
      n643Child.push(n644)
      var n646Child = []
      var n647Child = []
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][volume][longitude]`},{name:'value', val: track.volume.longitude},{name:'type', val: "number"},{name:'step', val: "0.000000001"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n647 = h('input', props, n647Child)
      n646Child.push(n647)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n646 = h('div', props, n646Child)
      n643Child.push(n646)
      var n648Child = []
      var n649Child = []
      n649Child.push("緯度")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n649 = h('label', props, n649Child)
      n648Child.push(n649)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n648 = h('div', props, n648Child)
      n643Child.push(n648)
      var n650Child = []
      var n651Child = []
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][volume][latitude]`},{name:'value', val: track.volume.latitude},{name:'type', val: "number"},{name:'step', val: "0.000000001"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n651 = h('input', props, n651Child)
      n650Child.push(n651)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n650 = h('div', props, n650Child)
      n643Child.push(n650)
      var n652Child = []
      var n653Child = []
      n653Child.push("距離音量對照")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n653 = h('label', props, n653Child)
      n652Child.push(n653)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n652 = h('div', props, n652Child)
      n643Child.push(n652)
      var n654Child = []
      var n655Child = []
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'textarea'},{name:'name', val: `tracks[${index}][volume][distanceVolumes]`},{name:'value', val: track.volume.distanceVolumes}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n655 = h('textarea', props, n655Child)
      n654Child.push(n655)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n654 = h('div', props, n654Child)
      n643Child.push(n654)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'card'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n643 = h('div', props, n643Child)
      n642Child.push(n643)
    } else {
      if('volume' in track) {
        var n656Child = []
        var n657Child = []
        var n658Child = []
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][volume]`},{name:'value', val: track.volume},{name:'type', val: "number"},{name:'step', val: "0.01"}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n658 = h('input', props, n658Child)
        n657Child.push(n658)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n657 = h('div', props, n657Child)
        n656Child.push(n657)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n656 = h('div', props, n656Child)
        n642Child.push(n656)
      }
    }
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'},{name:'key', val: "setting"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n642 = h('div', props, n642Child)
    n635Child.push(n642)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n635 = h('div', props, n635Child)
    n632Child.push(n635)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n632 = h('div', props, n632Child)
    n605Child.push(n632)
    var n659Child = []
    var n660Child = []
    var n661Child = []
    n661Child.push("音量漸變秒數")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n661 = h('label', props, n661Child)
    n660Child.push(n661)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n660 = h('div', props, n660Child)
    n659Child.push(n660)
    var n662Child = []
    var n663Child = []
    var n664Child = []
    var n665Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][volumeChangeSeconds]`},{name:'value', val: track.volumeChangeSeconds},{name:'type', val: "number"},{name:'step', val: "0.01"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n665 = h('input', props, n665Child)
    n664Child.push(n665)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n664 = h('div', props, n664Child)
    n663Child.push(n664)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n663 = h('div', props, n663Child)
    n662Child.push(n663)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n662 = h('div', props, n662Child)
    n659Child.push(n662)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n659 = h('div', props, n659Child)
    n605Child.push(n659)
    var n666Child = []
    var n667Child = []
    var n668Child = []
    n668Child.push("播放狀態")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n668 = h('label', props, n668Child)
    n667Child.push(n668)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n667 = h('div', props, n667Child)
    n666Child.push(n667)
    var n669Child = []
    var n670Child = []
    var n671Child = []
    if('pause' in track) {
      var n672Child = []
      n672Child.push("不變更暫停狀態")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'onclick', val: futureMerge(track, { pause: UNSET })}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n672 = h('a', props, n672Child)
      n671Child.push(n672)
      var n673Child = []
      var n674Child = []
      n674Child.push("暫停")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'checkbox'},{name:'name', val: `tracks[${index}][pause]`},{name:'value', val: "true"},{name:'checked', val: track.pause},{name:'type', val: "checkbox"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n674 = h('input', props, n674Child)
      n673Child.push(n674)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'checkbox'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n673 = h('label', props, n673Child)
      n671Child.push(n673)
    } else {
      var n675Child = []
      n675Child.push("變更暫停狀態")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'onclick', val: futureMerge(track, { pause: true })}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n675 = h('a', props, n675Child)
      n671Child.push(n675)
    }
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n671 = h('div', props, n671Child)
    n670Child.push(n671)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n670 = h('div', props, n670Child)
    n669Child.push(n670)
    var n676Child = []
    var n677Child = []
    var n678Child = []
    n678Child.push("播放位置")
    var props = {attributes: runtime.compileAttrs([], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n678 = h('p', props, n678Child)
    n677Child.push(n678)
    var n679Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][currentTime]`},{name:'value', val: track.currentTime},{name:'placeholder', val: "留空 = 不改變"},{name:'type', val: "number"},{name:'step', val: "0.01"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n679 = h('input', props, n679Child)
    n677Child.push(n679)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n677 = h('div', props, n677Child)
    n676Child.push(n677)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n676 = h('div', props, n676Child)
    n669Child.push(n676)
    var n680Child = []
    var n681Child = []
    var n682Child = []
    n682Child.push("停止位置")
    var props = {attributes: runtime.compileAttrs([], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n682 = h('p', props, n682Child)
    n681Child.push(n682)
    var n683Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `tracks[${index}][stopTime]`},{name:'value', val: track.stopTime},{name:'placeholder', val: "留空 = 不改變"},{name:'type', val: "number"},{name:'step', val: "0.01"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n683 = h('input', props, n683Child)
    n681Child.push(n683)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n681 = h('div', props, n681Child)
    n680Child.push(n681)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n680 = h('div', props, n680Child)
    n669Child.push(n680)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n669 = h('div', props, n669Child)
    n666Child.push(n669)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n666 = h('div', props, n666Child)
    n605Child.push(n666)
    var n684Child = []
    var n685Child = []
    var n686Child = []
    n686Child.push("淡入淡出")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n686 = h('label', props, n686Child)
    n685Child.push(n686)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n685 = h('div', props, n685Child)
    n684Child.push(n685)
    var n687Child = []
    var n688Child = []
    var n689Child = []
    var n690Child = []
    n690Child.push("入 (秒)")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-static'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n690 = h('div', props, n690Child)
    n689Child.push(n690)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n689 = h('div', props, n689Child)
    n688Child.push(n689)
    var n691Child = []
    var n692Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'type', val: "number"},{name:'name', val: `tracks[${index}][fadeInSeconds]`},{name:'value', val: track.fadeInSeconds},{name:'placeholder', val: "留空 = 不改變"},{name:'step', val: "0.01"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n692 = h('input', props, n692Child)
    n691Child.push(n692)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n691 = h('div', props, n691Child)
    n688Child.push(n691)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'has-addons'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n688 = h('div', props, n688Child)
    n687Child.push(n688)
    var n693Child = []
    var n694Child = []
    var n695Child = []
    n695Child.push("出 (秒)")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-static'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n695 = h('div', props, n695Child)
    n694Child.push(n695)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n694 = h('div', props, n694Child)
    n693Child.push(n694)
    var n696Child = []
    var n697Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'type', val: "number"},{name:'name', val: `tracks[${index}][fadeOutSeconds]`},{name:'value', val: track.fadeOutSeconds},{name:'placeholder', val: "留空 = 不改變"},{name:'step', val: "0.01"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n697 = h('input', props, n697Child)
    n696Child.push(n697)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n696 = h('div', props, n696Child)
    n693Child.push(n696)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'has-addons'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n693 = h('div', props, n693Child)
    n687Child.push(n693)
    var n698Child = []
    var n699Child = []
    var n700Child = []
    n700Child.push("最低音量")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-static'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n700 = h('div', props, n700Child)
    n699Child.push(n700)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n699 = h('div', props, n699Child)
    n698Child.push(n699)
    var n701Child = []
    var n702Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'type', val: "number"},{name:'name', val: `tracks[${index}][fadeMinVolume]`},{name:'value', val: track.fadeMinVolume},{name:'placeholder', val: "留空 = 不改變"},{name:'step', val: "0.01"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n702 = h('input', props, n702Child)
    n701Child.push(n702)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n701 = h('div', props, n701Child)
    n698Child.push(n701)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'has-addons'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n698 = h('div', props, n698Child)
    n687Child.push(n698)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n687 = h('div', props, n687Child)
    n684Child.push(n687)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n684 = h('div', props, n684Child)
    n605Child.push(n684)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'box'},{name:'key', val: track}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n605 = h('div', props, n605Child)
    n0Child.push(n605)
  }.bind(this))
  var n703Child = []
  var n704Child = []
  var n705Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-plus'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n705 = h('i', props, n705Child)
  n704Child.push(n705)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n704 = h('span', props, n704Child)
  n703Child.push(n704)
  var n706Child = []
  n706Child.push("加入音軌設定")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n706 = h('span', props, n706Child)
  n703Child.push(n706)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-primary'},{name:'onclick', val: this.addTrack},{name:'type', val: "button"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n703 = h('button', props, n703Child)
  n0Child.push(n703)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  