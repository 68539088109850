
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n801Child = []
  var n802Child = []
  var n803Child = []
  n803Child.push("訊息")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n803 = h('label', props, n803Child)
  n802Child.push(n803)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n802 = h('div', props, n802Child)
  n801Child.push(n802)
  var n804Child = []
  var n805Child = []
  var n806Child = []
  var n807Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "message"},{name:'value', val: shadow.message},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n807 = h('input', props, n807Child)
  n806Child.push(n807)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n806 = h('div', props, n806Child)
  n805Child.push(n806)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n805 = h('div', props, n805Child)
  n804Child.push(n805)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n804 = h('div', props, n804Child)
  n801Child.push(n804)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "message"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n801 = h('div', props, n801Child)
  n0Child.push(n801)
  var n808Child = []
  var n809Child = []
  var n810Child = []
  n810Child.push("再開啟時訊息")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n810 = h('label', props, n810Child)
  n809Child.push(n810)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n809 = h('div', props, n809Child)
  n808Child.push(n809)
  var n811Child = []
  var n812Child = []
  var n813Child = []
  var n814Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "retryMessage"},{name:'value', val: shadow.retryMessage},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n814 = h('input', props, n814Child)
  n813Child.push(n814)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n813 = h('div', props, n813Child)
  n812Child.push(n813)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n812 = h('div', props, n812Child)
  n811Child.push(n812)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n811 = h('div', props, n811Child)
  n808Child.push(n811)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "retryMessage"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n808 = h('div', props, n808Child)
  n0Child.push(n808)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  