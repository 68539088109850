
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n23Child = []
  var n24Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-background'},{name:'onclick', val: this.dismiss}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n24 = h('div', props, n24Child)
  n23Child.push(n24)
  var n25Child = []
  var n26Child = []
  var n27Child = []
  n27Child.push("劇本設定")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-title'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n27 = h('p', props, n27Child)
  n26Child.push(n27)
  var n28Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'delete'},{name:'onclick', val: this.dismiss},{name:'type', val: "button"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n28 = h('button', props, n28Child)
  n26Child.push(n28)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-head'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n26 = h('header', props, n26Child)
  n25Child.push(n26)
  var n29Child = []
  var n30Child = []
  var n31Child = []
  var n32Child = []
  var n33Child = []
  n33Child.push("標題")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n33 = h('label', props, n33Child)
  n32Child.push(n33)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n32 = h('div', props, n32Child)
  n31Child.push(n32)
  var n34Child = []
  var n35Child = []
  var n36Child = []
  var n37Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'type', val: "text"},{name:'name', val: "playbook[title]"},{name:'value', val: this.data.title}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n37 = h('input', props, n37Child)
  n36Child.push(n37)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n36 = h('div', props, n36Child)
  n35Child.push(n36)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n35 = h('div', props, n35Child)
  n34Child.push(n35)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n34 = h('div', props, n34Child)
  n31Child.push(n34)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n31 = h('div', props, n31Child)
  n30Child.push(n31)
  var n38Child = []
  var n39Child = []
  var n40Child = []
  n40Child.push("簡介")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n40 = h('label', props, n40Child)
  n39Child.push(n40)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n39 = h('div', props, n39Child)
  n38Child.push(n39)
  var n41Child = []
  var n42Child = []
  var n43Child = []
  var n44Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'textarea'},{name:'name', val: "playbook[description]"},{name:'value', val: this.data.description}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n44 = h('textarea', props, n44Child)
  n43Child.push(n44)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n43 = h('div', props, n43Child)
  n42Child.push(n43)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n42 = h('div', props, n42Child)
  n41Child.push(n42)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n41 = h('div', props, n41Child)
  n38Child.push(n41)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n38 = h('div', props, n38Child)
  n30Child.push(n38)
  var n45Child = []
  var n46Child = []
  var n47Child = []
  n47Child.push("執行環境")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n47 = h('label', props, n47Child)
  n46Child.push(n47)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n46 = h('div', props, n46Child)
  n45Child.push(n46)
  var n48Child = []
  var n49Child = []
  var n50Child = []
  var n51Child = []
  var n52Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'mr-1'},{name:'type', val: "checkbox"},{name:'name', val: "playbook[platforms][]"},{name:'value', val: "app"},{name:'checked', val: this.data.platforms.includes("app")}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n52 = h('input', props, n52Child)
  n51Child.push(n52)
  n51Child.push("手機 App")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'checkbox'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n51 = h('label', props, n51Child)
  n50Child.push(n51)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n50 = h('div', props, n50Child)
  n49Child.push(n50)
  var n53Child = []
  var n54Child = []
  var n55Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'mr-1'},{name:'type', val: "checkbox"},{name:'name', val: "playbook[platforms][]"},{name:'value', val: "web"},{name:'checked', val: this.data.platforms.includes("web")}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n55 = h('input', props, n55Child)
  n54Child.push(n55)
  n54Child.push("網頁 (部分功能無法使用)")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'checkbox'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n54 = h('label', props, n54Child)
  n53Child.push(n54)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n53 = h('div', props, n53Child)
  n49Child.push(n53)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n49 = h('div', props, n49Child)
  n48Child.push(n49)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n48 = h('div', props, n48Child)
  n45Child.push(n48)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n45 = h('div', props, n45Child)
  n30Child.push(n45)
  var n56Child = []
  var n57Child = []
  var n58Child = []
  n58Child.push("售票簡介")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n58 = h('label', props, n58Child)
  n57Child.push(n58)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n57 = h('div', props, n57Child)
  n56Child.push(n57)
  var n59Child = []
  var n60Child = []
  var n61Child = []
  var n62Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'textarea'},{name:'name', val: "playbook[ticketListingDescription]"},{name:'value', val: this.data.ticketListingDescription}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n62 = h('textarea', props, n62Child)
  n61Child.push(n62)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n61 = h('div', props, n61Child)
  n60Child.push(n61)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n60 = h('div', props, n60Child)
  n59Child.push(n60)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n59 = h('div', props, n59Child)
  n56Child.push(n59)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n56 = h('div', props, n56Child)
  n30Child.push(n56)
  var n63Child = []
  var n64Child = []
  var n65Child = []
  n65Child.push("圖片 Asset ID")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n65 = h('label', props, n65Child)
  n64Child.push(n65)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n64 = h('div', props, n64Child)
  n63Child.push(n64)
  var n66Child = []
  var n67Child = []
  var n68Child = []
  var n69Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'textarea'},{name:'name', val: "playbook[thumbnailAssetId]"},{name:'value', val: this.data.thumbnailAssetId}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n69 = h('textarea', props, n69Child)
  n68Child.push(n69)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n68 = h('div', props, n68Child)
  n67Child.push(n68)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n67 = h('div', props, n67Child)
  n66Child.push(n67)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n66 = h('div', props, n66Child)
  n63Child.push(n66)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n63 = h('div', props, n63Child)
  n30Child.push(n63)
  var n70Child = []
  var n71Child = []
  var n72Child = []
  n72Child.push("全體使用者公開")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n72 = h('label', props, n72Child)
  n71Child.push(n72)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n71 = h('div', props, n71Child)
  n70Child.push(n71)
  var n73Child = []
  var n74Child = []
  var n75Child = []
  var n76Child = []
  var props = {attributes: runtime.compileAttrs([{name:'type', val: "hidden"},{name:'name', val: "playbook[public]"},{name:'value', val: "false"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n76 = h('input', props, n76Child)
  n75Child.push(n76)
  var n77Child = []
  var props = {attributes: runtime.compileAttrs([{name:'type', val: "checkbox"},{name:'name', val: "playbook[public]"},{name:'value', val: "true"},{name:'checked', val: this.data.public}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n77 = h('input', props, n77Child)
  n75Child.push(n77)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n75 = h('div', props, n75Child)
  n74Child.push(n75)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n74 = h('div', props, n74Child)
  n73Child.push(n74)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n73 = h('div', props, n73Child)
  n70Child.push(n73)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n70 = h('div', props, n70Child)
  n30Child.push(n70)
  var n78Child = []
  var n79Child = []
  var n80Child = []
  n80Child.push("鎖定內容編輯")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n80 = h('label', props, n80Child)
  n79Child.push(n80)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n79 = h('div', props, n79Child)
  n78Child.push(n79)
  var n81Child = []
  var n82Child = []
  var n83Child = []
  var n84Child = []
  var props = {attributes: runtime.compileAttrs([{name:'type', val: "hidden"},{name:'name', val: "playbook[protected]"},{name:'value', val: "false"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n84 = h('input', props, n84Child)
  n83Child.push(n84)
  var n85Child = []
  var props = {attributes: runtime.compileAttrs([{name:'type', val: "checkbox"},{name:'name', val: "playbook[protected]"},{name:'value', val: "true"},{name:'checked', val: this.data.protected}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n85 = h('input', props, n85Child)
  n83Child.push(n85)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n83 = h('div', props, n83Child)
  n82Child.push(n83)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n82 = h('div', props, n82Child)
  n81Child.push(n82)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n81 = h('div', props, n81Child)
  n78Child.push(n81)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n78 = h('div', props, n78Child)
  n30Child.push(n78)
  var n86Child = []
  var n87Child = []
  var n88Child = []
  n88Child.push("國家代碼")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n88 = h('label', props, n88Child)
  n87Child.push(n88)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n87 = h('div', props, n87Child)
  n86Child.push(n87)
  var n89Child = []
  var n90Child = []
  var n91Child = []
  var n92Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'type', val: "text"},{name:'name', val: "playbook[countryAlpha2]"},{name:'value', val: this.data.countryAlpha2}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n92 = h('input', props, n92Child)
  n91Child.push(n92)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n91 = h('div', props, n91Child)
  n90Child.push(n91)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n90 = h('div', props, n90Child)
  n89Child.push(n90)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n89 = h('div', props, n89Child)
  n86Child.push(n89)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n86 = h('div', props, n86Child)
  n30Child.push(n86)
  var n93Child = []
  var n94Child = []
  var n95Child = []
  n95Child.push("分類")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n95 = h('label', props, n95Child)
  n94Child.push(n95)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n94 = h('div', props, n94Child)
  n93Child.push(n94)
  var n96Child = []
  var n97Child = []
  var n98Child = []
  var v99 = this.data.categories.concat(['', '', ''])
  Object.keys(v99).forEach(function (k100) {
    var category = v99[k100]
    var n101Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "playbook[categories][]"},{name:'value', val: category}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n101 = h('input', props, n101Child)
    n98Child.push(n101)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n98 = h('div', props, n98Child)
  n97Child.push(n98)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n97 = h('div', props, n97Child)
  n96Child.push(n97)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n96 = h('div', props, n96Child)
  n93Child.push(n96)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n93 = h('div', props, n93Child)
  n30Child.push(n93)
  var props = {attributes: runtime.compileAttrs([{name:'afterCreate', val: this.bindForm},{name:'afterUpdate', val: this.bindForm}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n30 = h('form', props, n30Child)
  n29Child.push(n30)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n29 = h('section', props, n29Child)
  n25Child.push(n29)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n25 = h('div', props, n25Child)
  n23Child.push(n25)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'meta-editor'},{name:'class', val: 'modal'},{name:'class', val: 'is-active'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n23 = h('div', props, n23Child)
  n0Child.push(n23)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  