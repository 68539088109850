
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n122Child = []
  var n123Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-background'},{name:'onclick', val: this.dismiss}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n123 = h('div', props, n123Child)
  n122Child.push(n123)
  var n124Child = []
  var n125Child = []
  var n126Child = []
  var n127Child = []
  var n128Child = []
  var n129Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-solid'},{name:'class', val: 'fa-trash'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n129 = h('i', props, n129Child)
  n128Child.push(n129)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n128 = h('div', props, n128Child)
  n127Child.push(n128)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'class', val: 'is-danger'},{name:'class', val: 'mr-1'},{name:'type', val: "button"},{name:'onclick', val: this.delete},{name:'title', val: "刪除"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n127 = h('button', props, n127Child)
  n126Child.push(n127)
  var n130Child = []
  var n131Child = []
  var n132Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-solid'},{name:'class', val: 'fa-copy'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n132 = h('i', props, n132Child)
  n131Child.push(n132)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n131 = h('div', props, n131Child)
  n130Child.push(n131)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'class', val: 'is-dark'},{name:'class', val: 'mr-2'},{name:'type', val: "button"},{name:'onclick', val: this.promptCopy},{name:'title', val: "複製"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n130 = h('button', props, n130Child)
  n126Child.push(n130)
  var n133Child = []
  var n134Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'class', val: 'is-small'},{name:'name', val: "_name"},{name:'value', val: this.scene._name},{name:'placeholder', val: "場景名稱"},{name:'oninput', val: this.onSceneNameInput}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n134 = h('input', props, n134Child)
  n133Child.push(n134)
  var n135Child = []
  var n136Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-tag'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n136 = h('i', props, n136Child)
  n135Child.push(n136)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'},{name:'class', val: 'is-left'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n135 = h('span', props, n135Child)
  n133Child.push(n135)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'},{name:'class', val: 'has-icons-left'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n133 = h('div', props, n133Child)
  n126Child.push(n133)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-title'},{name:'class', val: 'field'},{name:'class', val: 'is-flex'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n126 = h('div', props, n126Child)
  n125Child.push(n126)
  var n137Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'delete'},{name:'onclick', val: this.dismiss},{name:'type', val: "button"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n137 = h('button', props, n137Child)
  n125Child.push(n137)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-head'},{name:'oninput', val: this.onInput}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n125 = h('header', props, n125Child)
  n124Child.push(n125)
  var n138Child = []
  var n139Child = []
  var n140Child = []
  var n141Child = []
  var v142 = this.triggersMenu
  Object.keys(v142).forEach(function (k143) {
    var menuEntry = v142[k143]
    active = menuEntry.trigger == this.activeTrigger
    var n144Child = []
    var n145Child = []
    n145Child = n145Child.concat(menuEntry.label)
    var props = {attributes: runtime.compileAttrs([{name:'href', val: "javascript:void(0)"},{name:'onclick', val: this.selectTrigger.bind(this, menuEntry.trigger)},{name:'classes', val: {'is-active': active}}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n145 = h('a', props, n145Child)
    n144Child.push(n145)
    if(menuEntry.trigger) {
      var n146Child = []
      var n147Child = []
      var n148Child = []
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'far'},{name:'class', val: 'fa-trash-alt'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n148 = h('i', props, n148Child)
      n147Child.push(n148)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n147 = h('span', props, n147Child)
      n146Child.push(n147)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'class', val: 'is-danger'},{name:'type', val: "button"},{name:'onclick', val: this.deleteTrigger.bind(this, menuEntry.trigger)}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n146 = h('button', props, n146Child)
      n144Child.push(n146)
    }
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'has-addons'},{name:'key', val: menuEntry}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n144 = h('li', props, n144Child)
    n141Child.push(n144)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'menu-list'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n141 = h('ul', props, n141Child)
  n140Child.push(n141)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'triggers'},{name:'class', val: 'menu'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n140 = h('div', props, n140Child)
  n139Child.push(n140)
  var n149Child = []
  var n150Child = []
  var n151Child = []
  var n152Child = []
  var n153Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-plus'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n153 = h('i', props, n153Child)
  n152Child.push(n153)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n152 = h('span', props, n152Child)
  n151Child.push(n152)
  var n154Child = []
  n154Child.push("新增事件")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n154 = h('span', props, n154Child)
  n151Child.push(n154)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'is-block'},{name:'class', val: 'button'},{name:'type', val: "button"},{name:'tabindex', val: "0"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n151 = h('a', props, n151Child)
  n150Child.push(n151)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown-trigger'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n150 = h('div', props, n150Child)
  n149Child.push(n150)
  var n155Child = []
  var n156Child = []
  var v157 = this.constructor.TRIGGERS
  Object.keys(v157).forEach(function (type) {
    var triggerClass = v157[type]
    var n158Child = []
    n158Child = n158Child.concat(triggerClass.label)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown-item'},{name:'href', val: "javascript:void(0)"},{name:'key', val: triggerClass},{name:'tabindex', val: "0"},{name:'onclick', val: this.addTrigger.bind(this, triggerClass)}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n158 = h('a', props, n158Child)
    n156Child.push(n158)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown-content'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n156 = h('div', props, n156Child)
  n155Child.push(n156)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown-menu'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n155 = h('div', props, n155Child)
  n149Child.push(n155)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown'},{name:'class', val: 'add-trigger'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n149 = h('div', props, n149Child)
  n139Child.push(n149)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'triggers'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n139 = h('aside', props, n139Child)
  n138Child.push(n139)
  var n159Child = []
  if(this.activeTriggerForm) {
    n159Child = n159Child.concat(this.activeTriggerForm.render())
  }
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'edit-active-trigger'},{name:'classes', val: {active: this.editingActiveTrigger}},{name:'key', val: this.activeTriggerForm}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n159 = h('section', props, n159Child)
  n138Child.push(n159)
  var n160Child = []
  var n161Child = []
  if(this.activeTrigger) {
    var n162Child = []
    var n163Child = []
    var n164Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-cog'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n164 = h('i', props, n164Child)
    n163Child.push(n164)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n163 = h('span', props, n163Child)
    n162Child.push(n163)
    n162Child.push("編輯")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'type', val: "button"},{name:'onclick', val: this.editActiveTrigger}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n162 = h('button', props, n162Child)
    n161Child.push(n162)
  }
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'action-info'},{name:'style', val: "background: #CCFFFF"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n161 = h('div', props, n161Child)
  n160Child.push(n161)
  var n165Child = []
  var n166Child = []
  var v167 = this.actionsMenu
  Object.keys(v167).forEach(function (k168) {
    var menuEntry = v167[k168]
    var n169Child = []
    var n170Child = []
    n170Child = n170Child.concat(menuEntry.label)
    var props = {attributes: runtime.compileAttrs([{name:'href', val: "javascript:void(0)"},{name:'onclick', val: this.selectAction.bind(this, menuEntry.type)},{name:'classes', val: { "is-active": this.activeActionType == menuEntry.type }}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n170 = h('a', props, n170Child)
    n169Child.push(n170)
    var n171Child = []
    var n172Child = []
    var n173Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'far'},{name:'class', val: 'fa-trash-alt'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n173 = h('i', props, n173Child)
    n172Child.push(n173)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n172 = h('span', props, n172Child)
    n171Child.push(n172)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'class', val: 'is-danger'},{name:'type', val: "button"},{name:'onclick', val: this.deleteAction.bind(this, menuEntry.type)}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n171 = h('button', props, n171Child)
    n169Child.push(n171)
    var props = {attributes: runtime.compileAttrs([{name:'key', val: menuEntry}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n169 = h('li', props, n169Child)
    n166Child.push(n169)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'menu-list'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n166 = h('ul', props, n166Child)
  n165Child.push(n166)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'actions'},{name:'class', val: 'menu'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n165 = h('div', props, n165Child)
  n160Child.push(n165)
  var n174Child = []
  var n175Child = []
  var n176Child = []
  var n177Child = []
  var n178Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-plus'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n178 = h('i', props, n178Child)
  n177Child.push(n178)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n177 = h('span', props, n177Child)
  n176Child.push(n177)
  var n179Child = []
  n179Child.push("新增行為")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n179 = h('span', props, n179Child)
  n176Child.push(n179)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'is-block'},{name:'class', val: 'button'},{name:'type', val: "button"},{name:'tabindex', val: "0"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n176 = h('a', props, n176Child)
  n175Child.push(n176)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown-trigger'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n175 = h('div', props, n175Child)
  n174Child.push(n175)
  var n180Child = []
  var n181Child = []
  var v182 = this.addActionsMenu
  Object.keys(v182).forEach(function (k183) {
    var menuEntry = v182[k183]
    var n184Child = []
    n184Child = n184Child.concat(menuEntry.label)
    if(menuEntry.conflict) {
      conflictLabel = this.constructor.ACTIONS[menuEntry.conflict].label
      n184Child = n184Child.concat(` (與 ${conflictLabel} 不相容)`)
    }
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown-item'},{name:'classes', val: {conflict: menuEntry.conflict}},{name:'href', val: "javascript:void(0)"},{name:'key', val: menuEntry},{name:'tabindex', val: "0"},{name:'onclick', val: (menuEntry.conflict ? null : this.addAction.bind(this, menuEntry))}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n184 = h('a', props, n184Child)
    n181Child.push(n184)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown-content'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n181 = h('div', props, n181Child)
  n180Child.push(n181)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown-menu'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n180 = h('div', props, n180Child)
  n174Child.push(n180)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'dropdown'},{name:'class', val: 'add-action'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n174 = h('div', props, n174Child)
  n160Child.push(n174)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'actions'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n160 = h('section', props, n160Child)
  n138Child.push(n160)
  var n185Child = []
  if(this.activeActionForm) {
    n185Child = n185Child.concat(this.activeActionForm.render())
  }
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'action-form'},{name:'class', val: hyphenize(this.activeActionType || "")},{name:'key', val: this.activeActionForm}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n185 = h('section', props, n185Child)
  n138Child.push(n185)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n138 = h('section', props, n138Child)
  n124Child.push(n138)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n124 = h('div', props, n124Child)
  n122Child.push(n124)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'scene-editor'},{name:'class', val: 'modal'},{name:'class', val: 'is-active'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n122 = h('div', props, n122Child)
  n0Child.push(n122)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  