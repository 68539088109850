import Base from './base'
import renderFields from './change-playbook-thumbnail.pug'

export default class ChangePlaybookThumbnail extends Base
  @label: "變更縮圖"

  @DEFAULTS:
    assetId: null

  renderFields: renderFields
