import renderButton from '!pug-loader!./button.pug'
import renderInput from '!pug-loader!./input.pug'

export default
  button:
    name: "底圖、文、按鈕"
    render: renderButton
    domIdFields:
      buttonId:
        type: "button"
        triggerNameField: "buttonText"

    form:
      buttonId: "按鈕識別符"
      backgroudAssetId: "底圖"
      text: "文字"
      buttonText: "按鈕文字"

  input:
    name: "底圖、文、輸入框"
    render: renderInput
    domIdFields:
      inputId:
        type: "input"
        triggerNameField: "placeholder"
      buttonId:
        type: 'button'
        triggerNameField: "buttonText"

    form:
      backgroudAssetId: "底圖"
      text: "文字"
      placeholder: "文字框提示"
      buttonText: "按鈕文字"
      inputId: "輸入框識別符"
      buttonId: "按鈕識別符"
