
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n829Child = []
  var n830Child = []
  var n831Child = []
  var n832Child = []
  n832Child.push("名稱")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n832 = h('label', props, n832Child)
  n831Child.push(n832)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n831 = h('div', props, n831Child)
  n830Child.push(n831)
  var n833Child = []
  var n834Child = []
  var n835Child = []
  var n836Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "_name"},{name:'value', val: shadow._name}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n836 = h('input', props, n836Child)
  n835Child.push(n836)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n835 = h('div', props, n835Child)
  n834Child.push(n835)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n834 = h('div', props, n834Child)
  n833Child.push(n834)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n833 = h('div', props, n833Child)
  n830Child.push(n833)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n830 = h('div', props, n830Child)
  n829Child.push(n830)
  n829Child = n829Child.concat(children)
  var n837Child = []
  var n838Child = []
  var n839Child = []
  var n840Child = []
  n840Child.push("儲存")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-primary'},{name:'onclick', val: this.finish}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n840 = h('button', props, n840Child)
  n839Child.push(n840)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n839 = h('div', props, n839Child)
  n838Child.push(n839)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n838 = h('div', props, n838Child)
  n837Child.push(n838)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n837 = h('div', props, n837Child)
  n829Child.push(n837)
  var props = {attributes: runtime.compileAttrs([{name:'oninput', val: this.onInput},{name:'afterCreate', val: this.bindForm}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n829 = h('form', props, n829Child)
  n0Child.push(n829)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  