import RailsUjs from '@rails/ujs'
import { h, createProjector } from 'maquette'

import render from './assets-manager.pug'

export class FutureAsset
  content: null
  autoDelete: false

  constructor: (assignments)->
    Object.assign(this, assignments)

  upload: ->
    throw "playbookId required" unless @playbookId

    formData = new FormData()

    file = @file ? new Blob([@content], type: @contentType)
    formData.append("asset[file]", file)
    formData.append("asset[memo]", @memo) if @memo
    formData.append("auto_delete", JSON.stringify(@autoDelete == true))

    new Promise (resolve, reject)=>
      RailsUjs.ajax(
        type: "POST",
        url: "/playbooks/#{@playbookId}/assets",
        data: formData,
        success: (data)=>
          data.playbookId = @playbookId
          resolve(new Asset(data))

        error: (err)-> reject(err)
      )

  text: -> await @content

export class Asset
  changed: false

  constructor: (assetJson)->
    Object.assign(this, assetJson)

  text: ->
    response = await Chito.fetch("/playbook_assets/#{@id}")
    response.text()

  save: ->
    new Promise (resolve, reject)=>
      formData = new FormData()
      formData.append("asset[memo]", @memo)
      formData.append("auto_delete", @autoDelete)

      RailsUjs.ajax(
        type: "PATCH",
        url: "/playbooks/#{@playbookId}/assets/#{@id}",
        data: formData,
        success: (data)=>
          Object.assign(this, data)
          @changed = false
          resolve(this)

        error: (err)-> reject(err)
      )

export default class AssetsManager
  # assetId => asset
  assets: null

  # subject => asset
  futureAssetMap: null

  constructor: (@playbookEditor, assetsData)->
    @assets = new Map()
    @futureAssetMap = new Map()

    for assetData from assetsData
      assetData.playbookId = @playbookEditor.playbookId
      @assets.set(assetData.id, new Asset(assetData))

    (@projector = createProjector()).replace(
      document.querySelector("aside section.assets"),
      => render.call(this)[0]
    )

  assetFor: (subject)->
    return null unless subject?
    return futureAsset if (futureAsset = @futureAssetMap.get(subject))

    @assets.get(subject.assetId) if subject.assetId

  # replace asset of an object to a new future asset
  future: (subject, assetData)->
    futureAsset = new FutureAsset({playbookId: @playbookEditor.playbookId, ...assetData})
    @futureAssetMap.set(subject, futureAsset)

    futureAsset

  upload: ->
    for [subject, futureAsset] from @futureAssetMap.entries()
      asset = await futureAsset.upload()
      subject.assetId = asset.id
      @assets.set(asset.id, asset)

      @futureAssetMap.delete(subject)

  flushChanges: =>
    @flushChangesTimer = false

    for [assetId, asset] from @assets
      asset.save()

  onMemoInput: (asset, e)=>
    asset.memo = e.target.value
    asset.changed = true

    clearTimeout(@flushChangesTimer) if @flushChangesTimer
    @flushChangesTimer = setTimeout(@flushChanges, 2000)

  onAutoDeleteInput: (asset, e)=>
    asset.autoDelete = e.target.checked
    asset.save()

  onAddClick: =>
    el = document.createElement("input")
    el.type = "file"
    el.multiple = true
    el.addEventListener "change", =>
      for file in el.files
        futureAsset = new FutureAsset({playbookId: @playbookEditor.playbookId, file: file})
        asset = await futureAsset.upload()
        @assets.set(asset.id, asset)
        @projector.scheduleRender()

    el.click()
