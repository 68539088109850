
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n200Child = []
  var n201Child = []
  var n202Child = []
  var n203Child = []
  n203Child.push("模式")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'},{name:'class', val: 'nowrap'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n203 = h('label', props, n203Child)
  n202Child.push(n203)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n202 = h('div', props, n202Child)
  n201Child.push(n202)
  var n204Child = []
  var n205Child = []
  var n206Child = []
  var n207Child = []
  var n208Child = []
  var n209Child = []
  n209Child.push("網頁")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "webview"},{name:'selected', val: shadow.type == "webview"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n209 = h('option', props, n209Child)
  n208Child.push(n209)
  var n210Child = []
  n210Child.push("地圖")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "map"},{name:'selected', val: shadow.type == "map"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n210 = h('option', props, n210Child)
  n208Child.push(n210)
  var n211Child = []
  n211Child.push("影片")
  var props = {attributes: runtime.compileAttrs([{name:'value', val: "video"},{name:'selected', val: shadow.type == "video"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n211 = h('option', props, n211Child)
  n208Child.push(n211)
  var props = {attributes: runtime.compileAttrs([{name:'name', val: "type"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n208 = h('select', props, n208Child)
  n207Child.push(n208)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'select'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n207 = h('div', props, n207Child)
  n206Child.push(n207)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n206 = h('div', props, n206Child)
  n205Child.push(n206)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n205 = h('div', props, n205Child)
  n204Child.push(n205)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n204 = h('div', props, n204Child)
  n201Child.push(n204)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'column'},{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "type"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n201 = h('div', props, n201Child)
  n200Child.push(n201)
  if(shadow.type == "webview") {
    var n212Child = []
    var n213Child = []
    var n214Child = []
    n214Child.push("樣板")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'},{name:'class', val: 'nowrap'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n214 = h('label', props, n214Child)
    n213Child.push(n214)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n213 = h('div', props, n213Child)
    n212Child.push(n213)
    var n215Child = []
    var n216Child = []
    var n217Child = []
    var n218Child = []
    var n219Child = []
    var n220Child = []
    n220Child.push("自訂")
    var props = {attributes: runtime.compileAttrs([{name:'value', val: "custom"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n220 = h('option', props, n220Child)
    n219Child.push(n220)
    var v221 = this.constructor.HTML_TEMPLATES
    Object.keys(v221).forEach(function (key) {
      var template = v221[key]
      var n222Child = []
      n222Child = n222Child.concat(template.name)
      var props = {attributes: runtime.compileAttrs([{name:'value', val: key}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n222 = h('option', props, n222Child)
      n219Child.push(n222)
    }.bind(this))
    var props = {attributes: runtime.compileAttrs([{name:'name', val: "_template"},{name:'value', val: (shadow._template || "custom")}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n219 = h('select', props, n219Child)
    n218Child.push(n219)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'select'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n218 = h('div', props, n218Child)
    n217Child.push(n218)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n217 = h('div', props, n217Child)
    n216Child.push(n217)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n216 = h('div', props, n216Child)
    n215Child.push(n216)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n215 = h('div', props, n215Child)
    n212Child.push(n215)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'column'},{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "template"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n212 = h('div', props, n212Child)
    n200Child.push(n212)
  }
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'columns'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n200 = h('div', props, n200Child)
  n0Child.push(n200)
  switch(shadow.type) {
    case "webview":
      if(this.template) {
        var v223 = this.template.form
        Object.keys(v223).forEach(function (key) {
          var label = v223[key]
          var n224Child = []
          var n225Child = []
          var n226Child = []
          n226Child = n226Child.concat(label)
          var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'},{name:'class', val: 'nowrap'}], [])};
          if (props.attributes.id) props.key = props.attributes.id;
          var n226 = h('label', props, n226Child)
          n225Child.push(n226)
          var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
          if (props.attributes.id) props.key = props.attributes.id;
          var n225 = h('div', props, n225Child)
          n224Child.push(n225)
          var n227Child = []
          var n228Child = []
          var n229Child = []
          var n230Child = []
          var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'type', val: "text"},{name:'name', val: `_templateData[${key}]`},{name:'value', val: this.templateData(key)}], [])};
          if (props.attributes.id) props.key = props.attributes.id;
          var n230 = h('input', props, n230Child)
          n229Child.push(n230)
          if(domIdField = this.template.domIdFields[key]) {
            switch(domIdField.type) {
              case "button":
                var n231Child = []
                n231Child.push("設定點擊行為")
                var props = {attributes: runtime.compileAttrs([{name:'type', val: "button"},{name:'onclick', val: this.editClickTrigger.bind(this, key)}], [])};
                if (props.attributes.id) props.key = props.attributes.id;
                var n231 = h('button', props, n231Child)
                n229Child.push(n231)
                break;
              case "input":
                var n232Child = []
                n232Child.push("新增輸入值行為")
                var props = {attributes: runtime.compileAttrs([{name:'type', val: "button"},{name:'onclick', val: this.addInputResponse.bind(this, key)}], [])};
                if (props.attributes.id) props.key = props.attributes.id;
                var n232 = h('button', props, n232Child)
                n229Child.push(n232)
                break;
            }
          }
          var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
          if (props.attributes.id) props.key = props.attributes.id;
          var n229 = h('div', props, n229Child)
          n228Child.push(n229)
          var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
          if (props.attributes.id) props.key = props.attributes.id;
          var n228 = h('div', props, n228Child)
          n227Child.push(n228)
          var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
          if (props.attributes.id) props.key = props.attributes.id;
          var n227 = h('div', props, n227Child)
          n224Child.push(n227)
          var props = {attributes: runtime.compileAttrs([{name:'class', val: 'is-horizontal'},{name:'key', val: key}], [])};
          if (props.attributes.id) props.key = props.attributes.id;
          var n224 = h('field', props, n224Child)
          n0Child.push(n224)
        }.bind(this))
      } else {
        var n233Child = []
        var n234Child = []
        n234Child.push("HTML")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n234 = h('label', props, n234Child)
        n233Child.push(n234)
        var n235Child = []
        var n236Child = []
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'textarea'},{name:'name', val: "displayHtml"},{name:'value', val: shadow.displayHtml}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n236 = h('textarea', props, n236Child)
        n235Child.push(n236)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n235 = h('div', props, n235Child)
        n233Child.push(n235)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'key', val: "displayHtml"}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n233 = h('div', props, n233Child)
        n0Child.push(n233)
      }
      var n237Child = []
      var n238Child = []
      var props = {attributes: runtime.compileAttrs([{name:'afterCreate', val: this.updatePreviewFrame},{name:'afterUpdate', val: this.updatePreviewFrame}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n238 = h('iframe', props, n238Child)
      n237Child.push(n238)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'preview-container'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n237 = h('div', props, n237Child)
      n0Child.push(n237)
      break;
    case "map":
      var n239Child = []
      var n240Child = []
      var n241Child = []
      n241Child.push("目前位置圖示")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n241 = h('label', props, n241Child)
      n240Child.push(n241)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n240 = h('div', props, n240Child)
      n239Child.push(n240)
      var n242Child = []
      var n243Child = []
      var n244Child = []
      var n245Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "currentLocationMarker[assetId]"},{name:'value', val: shadow.currentLocationMarker.assetId}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n245 = h('input', props, n245Child)
      n244Child.push(n245)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n244 = h('div', props, n244Child)
      n243Child.push(n244)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n243 = h('div', props, n243Child)
      n242Child.push(n243)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n242 = h('div', props, n242Child)
      n239Child.push(n242)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "currentLocationMarker[assetId]"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n239 = h('div', props, n239Child)
      n0Child.push(n239)
      var n246Child = []
      var n247Child = []
      var n248Child = []
      n248Child.push("目前位置圖示大小")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n248 = h('label', props, n248Child)
      n247Child.push(n248)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n247 = h('div', props, n247Child)
      n246Child.push(n247)
      var n249Child = []
      var n250Child = []
      var n251Child = []
      var n252Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "currentLocationMarker[size]"},{name:'type', val: "number"},{name:'value', val: shadow.currentLocationMarker.size}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n252 = h('input', props, n252Child)
      n251Child.push(n252)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n251 = h('div', props, n251Child)
      n250Child.push(n251)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n250 = h('div', props, n250Child)
      n249Child.push(n250)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n249 = h('div', props, n249Child)
      n246Child.push(n249)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "currentLocationMarker[size]"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n246 = h('div', props, n246Child)
      n0Child.push(n246)
      var n253Child = []
      var n254Child = []
      n254Child.push("地標")
      var props = {attributes: runtime.compileAttrs([], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n254 = h('b', props, n254Child)
      n253Child.push(n254)
      var n255Child = []
      var n256Child = []
      var n257Child = []
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-plus'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n257 = h('i', props, n257Child)
      n256Child.push(n257)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n256 = h('span', props, n256Child)
      n255Child.push(n256)
      var n258Child = []
      n258Child.push("新增")
      var props = {attributes: runtime.compileAttrs([], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n258 = h('span', props, n258Child)
      n255Child.push(n258)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-primary'},{name:'onclick', val: this.addPositionMarker},{name:'type', val: "button"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n255 = h('button', props, n255Child)
      n253Child.push(n255)
      var v259 = shadow.positionMarkers
      Object.keys(v259).forEach(function (index) {
        var positionMarker = v259[index]
        var n260Child = []
        var n261Child = []
        n261Child.push("刪除")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'class', val: 'is-danger'},{name:'type', val: "button"},{name:'onclick', val: this.deletePositionMarker.bind(this, positionMarker)}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n261 = h('button', props, n261Child)
        n260Child.push(n261)
        var n262Child = []
        var n263Child = []
        var n264Child = []
        n264Child.push("經度")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n264 = h('label', props, n264Child)
        n263Child.push(n264)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n263 = h('div', props, n263Child)
        n262Child.push(n263)
        var n265Child = []
        var n266Child = []
        var n267Child = []
        var n268Child = []
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `positionMarkers[${index}][longitude]`},{name:'value', val: positionMarker.longitude},{name:'type', val: "number"},{name:'step', val: "0.000000001"}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n268 = h('input', props, n268Child)
        n267Child.push(n268)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n267 = h('div', props, n267Child)
        n266Child.push(n267)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n266 = h('div', props, n266Child)
        n265Child.push(n266)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n265 = h('div', props, n265Child)
        n262Child.push(n265)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n262 = h('div', props, n262Child)
        n260Child.push(n262)
        var n269Child = []
        var n270Child = []
        var n271Child = []
        n271Child.push("緯度")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n271 = h('label', props, n271Child)
        n270Child.push(n271)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n270 = h('div', props, n270Child)
        n269Child.push(n270)
        var n272Child = []
        var n273Child = []
        var n274Child = []
        var n275Child = []
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: `positionMarkers[${index}][latitude]`},{name:'value', val: positionMarker.latitude},{name:'type', val: "number"},{name:'step', val: "0.000000001"}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n275 = h('input', props, n275Child)
        n274Child.push(n275)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n274 = h('div', props, n274Child)
        n273Child.push(n274)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n273 = h('div', props, n273Child)
        n272Child.push(n273)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n272 = h('div', props, n272Child)
        n269Child.push(n272)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n269 = h('div', props, n269Child)
        n260Child.push(n269)
        var n276Child = []
        var n277Child = []
        var n278Child = []
        n278Child.push("資源ID")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n278 = h('label', props, n278Child)
        n277Child.push(n278)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n277 = h('div', props, n277Child)
        n276Child.push(n277)
        var n279Child = []
        var n280Child = []
        var n281Child = []
        var n282Child = []
        var props = {attributes: runtime.compileAttrs([{name:'name', val: `positionMarkers[${index}][assetId]`},{name:'value', val: positionMarker.assetId}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n282 = h('input', props, n282Child)
        n281Child.push(n282)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n281 = h('div', props, n281Child)
        n280Child.push(n281)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n280 = h('div', props, n280Child)
        n279Child.push(n280)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n279 = h('div', props, n279Child)
        n276Child.push(n279)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n276 = h('div', props, n276Child)
        n260Child.push(n276)
        var n283Child = []
        var n284Child = []
        var n285Child = []
        n285Child.push("大小")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n285 = h('label', props, n285Child)
        n284Child.push(n285)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n284 = h('div', props, n284Child)
        n283Child.push(n284)
        var n286Child = []
        var n287Child = []
        var n288Child = []
        var n289Child = []
        var props = {attributes: runtime.compileAttrs([{name:'name', val: `positionMarkers[${index}][size]`},{name:'type', val: "number"},{name:'value', val: positionMarker.size}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n289 = h('input', props, n289Child)
        n288Child.push(n289)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n288 = h('div', props, n288Child)
        n287Child.push(n288)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n287 = h('div', props, n287Child)
        n286Child.push(n287)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n286 = h('div', props, n286Child)
        n283Child.push(n286)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n283 = h('div', props, n283Child)
        n260Child.push(n283)
        var n290Child = []
        var n291Child = []
        var n292Child = []
        n292Child.push("堆疊優先度")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n292 = h('label', props, n292Child)
        n291Child.push(n292)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n291 = h('div', props, n291Child)
        n290Child.push(n291)
        var n293Child = []
        var n294Child = []
        var n295Child = []
        var n296Child = []
        var props = {attributes: runtime.compileAttrs([{name:'name', val: `positionMarkers[${index}][zIndex]`},{name:'type', val: "number"},{name:'value', val: positionMarker.zIndex}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n296 = h('input', props, n296Child)
        n295Child.push(n296)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n295 = h('div', props, n295Child)
        n294Child.push(n295)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n294 = h('div', props, n294Child)
        n293Child.push(n294)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n293 = h('div', props, n293Child)
        n290Child.push(n293)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n290 = h('div', props, n290Child)
        n260Child.push(n290)
        var n297Child = []
        var props = {attributes: runtime.compileAttrs([], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n297 = h('hr', props, n297Child)
        n260Child.push(n297)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'position-marker'},{name:'key', val: positionMarker}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n260 = h('div', props, n260Child)
        n253Child.push(n260)
      }.bind(this))
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'position-markers'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n253 = h('div', props, n253Child)
      n0Child.push(n253)
      var n298Child = []
      var n299Child = []
      var n300Child = []
      n300Child.push("樣式")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n300 = h('label', props, n300Child)
      n299Child.push(n300)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n299 = h('div', props, n299Child)
      n298Child.push(n299)
      var n301Child = []
      var n302Child = []
      var n303Child = []
      var n304Child = []
      n304Child = n304Child.concat(shadow.styleJSON)
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "styleJSON"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n304 = h('textarea', props, n304Child)
      n303Child.push(n304)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n303 = h('div', props, n303Child)
      n302Child.push(n303)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n302 = h('div', props, n302Child)
      n301Child.push(n302)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n301 = h('div', props, n301Child)
      n298Child.push(n301)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "styleJSON"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n298 = h('div', props, n298Child)
      n0Child.push(n298)
      break;
    case "video":
      if(!(shadow.youtubeVideoId)) {
        var n305Child = []
        var n306Child = []
        var n307Child = []
        n307Child.push("資源 ID")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n307 = h('label', props, n307Child)
        n306Child.push(n307)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n306 = h('div', props, n306Child)
        n305Child.push(n306)
        var n308Child = []
        var n309Child = []
        var n310Child = []
        var n311Child = []
        var props = {attributes: runtime.compileAttrs([{name:'name', val: "assetId"},{name:'type', val: "text"},{name:'value', val: shadow.assetId}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n311 = h('input', props, n311Child)
        n310Child.push(n311)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n310 = h('div', props, n310Child)
        n309Child.push(n310)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n309 = h('div', props, n309Child)
        n308Child.push(n309)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n308 = h('div', props, n308Child)
        n305Child.push(n308)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "assetId"}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n305 = h('div', props, n305Child)
        n0Child.push(n305)
      }
      if(!(shadow.assetId)) {
        var n312Child = []
        var n313Child = []
        var n314Child = []
        n314Child.push("YouTube 影片 ID")
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n314 = h('label', props, n314Child)
        n313Child.push(n314)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n313 = h('div', props, n313Child)
        n312Child.push(n313)
        var n315Child = []
        var n316Child = []
        var n317Child = []
        var n318Child = []
        var props = {attributes: runtime.compileAttrs([{name:'name', val: "youtubeVideoId"},{name:'type', val: "text"},{name:'value', val: shadow.youtubeVideoId}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n318 = h('input', props, n318Child)
        n317Child.push(n318)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n317 = h('div', props, n317Child)
        n316Child.push(n317)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n316 = h('div', props, n316Child)
        n315Child.push(n316)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n315 = h('div', props, n315Child)
        n312Child.push(n315)
        var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "youtubeVideoId"}], [])};
        if (props.attributes.id) props.key = props.attributes.id;
        var n312 = h('div', props, n312Child)
        n0Child.push(n312)
      }
      var n319Child = []
      var n320Child = []
      var n321Child = []
      n321Child.push("360 影片")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n321 = h('label', props, n321Child)
      n320Child.push(n321)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n320 = h('div', props, n320Child)
      n319Child.push(n320)
      var n322Child = []
      var n323Child = []
      var n324Child = []
      var n325Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "is360"},{name:'type', val: "checkbox"},{name:'value', val: "true"},{name:'checked', val: shadow.is360}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n325 = h('input', props, n325Child)
      n324Child.push(n325)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n324 = h('div', props, n324Child)
      n323Child.push(n324)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n323 = h('div', props, n323Child)
      n322Child.push(n323)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n322 = h('div', props, n322Child)
      n319Child.push(n322)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "is360"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n319 = h('div', props, n319Child)
      n0Child.push(n319)
      var n326Child = []
      var n327Child = []
      var n328Child = []
      n328Child.push("全螢幕")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n328 = h('label', props, n328Child)
      n327Child.push(n328)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n327 = h('div', props, n327Child)
      n326Child.push(n327)
      var n329Child = []
      var n330Child = []
      var n331Child = []
      var n332Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "fullScreen"},{name:'type', val: "checkbox"},{name:'value', val: "true"},{name:'checked', val: shadow.fullScreen}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n332 = h('input', props, n332Child)
      n331Child.push(n332)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n331 = h('div', props, n331Child)
      n330Child.push(n331)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n330 = h('div', props, n330Child)
      n329Child.push(n330)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n329 = h('div', props, n329Child)
      n326Child.push(n329)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "fullScreen"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n326 = h('div', props, n326Child)
      n0Child.push(n326)
      var n333Child = []
      var n334Child = []
      var n335Child = []
      n335Child.push("轉向播放 (橫向)")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n335 = h('label', props, n335Child)
      n334Child.push(n335)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n334 = h('div', props, n334Child)
      n333Child.push(n334)
      var n336Child = []
      var n337Child = []
      var n338Child = []
      var n339Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "rotate"},{name:'type', val: "checkbox"},{name:'value', val: "true"},{name:'checked', val: shadow.rotate}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n339 = h('input', props, n339Child)
      n338Child.push(n339)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n338 = h('div', props, n338Child)
      n337Child.push(n338)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n337 = h('div', props, n337Child)
      n336Child.push(n337)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n336 = h('div', props, n336Child)
      n333Child.push(n336)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "rotate"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n333 = h('div', props, n333Child)
      n0Child.push(n333)
      var n340Child = []
      var n341Child = []
      var n342Child = []
      n342Child.push("直接開始播放")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n342 = h('label', props, n342Child)
      n341Child.push(n342)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n341 = h('div', props, n341Child)
      n340Child.push(n341)
      var n343Child = []
      var n344Child = []
      var n345Child = []
      var n346Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "autoPlay"},{name:'type', val: "checkbox"},{name:'value', val: "true"},{name:'checked', val: shadow.autoPlay}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n346 = h('input', props, n346Child)
      n345Child.push(n346)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n345 = h('div', props, n345Child)
      n344Child.push(n345)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n344 = h('div', props, n344Child)
      n343Child.push(n344)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n343 = h('div', props, n343Child)
      n340Child.push(n343)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "autoPlay"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n340 = h('div', props, n340Child)
      n0Child.push(n340)
      var n347Child = []
      var n348Child = []
      var n349Child = []
      n349Child.push("顯示進度條")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n349 = h('label', props, n349Child)
      n348Child.push(n349)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n348 = h('div', props, n348Child)
      n347Child.push(n348)
      var n350Child = []
      var n351Child = []
      var n352Child = []
      var n353Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "showSeekBar"},{name:'type', val: "checkbox"},{name:'value', val: "true"},{name:'checked', val: shadow.showSeekBar}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n353 = h('input', props, n353Child)
      n352Child.push(n353)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n352 = h('div', props, n352Child)
      n351Child.push(n352)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n351 = h('div', props, n351Child)
      n350Child.push(n351)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n350 = h('div', props, n350Child)
      n347Child.push(n350)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "showSeekBar"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n347 = h('div', props, n347Child)
      n0Child.push(n347)
      var n354Child = []
      var n355Child = []
      var n356Child = []
      n356Child.push("顯示播放/暫停 按鈕")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n356 = h('label', props, n356Child)
      n355Child.push(n356)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n355 = h('div', props, n355Child)
      n354Child.push(n355)
      var n357Child = []
      var n358Child = []
      var n359Child = []
      var n360Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "showPlayPauseButton"},{name:'type', val: "checkbox"},{name:'value', val: "true"},{name:'checked', val: shadow.showPlayPauseButton}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n360 = h('input', props, n360Child)
      n359Child.push(n360)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n359 = h('div', props, n359Child)
      n358Child.push(n359)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n358 = h('div', props, n358Child)
      n357Child.push(n358)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n357 = h('div', props, n357Child)
      n354Child.push(n357)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "showPlayPauseButton"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n354 = h('div', props, n354Child)
      n0Child.push(n354)
      var n361Child = []
      var n362Child = []
      var n363Child = []
      n363Child.push("顯示音量控制")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n363 = h('label', props, n363Child)
      n362Child.push(n363)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n362 = h('div', props, n362Child)
      n361Child.push(n362)
      var n364Child = []
      var n365Child = []
      var n366Child = []
      var n367Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "showVolumeControl"},{name:'type', val: "checkbox"},{name:'value', val: "true"},{name:'checked', val: shadow.showVolumeControl}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n367 = h('input', props, n367Child)
      n366Child.push(n367)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n366 = h('div', props, n366Child)
      n365Child.push(n366)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n365 = h('div', props, n365Child)
      n364Child.push(n365)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n364 = h('div', props, n364Child)
      n361Child.push(n364)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "showVolumeControl"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n361 = h('div', props, n361Child)
      n0Child.push(n361)
      var n368Child = []
      var n369Child = []
      var n370Child = []
      n370Child.push("暫停")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n370 = h('label', props, n370Child)
      n369Child.push(n370)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n369 = h('div', props, n369Child)
      n368Child.push(n369)
      var n371Child = []
      var n372Child = []
      var n373Child = []
      var n374Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "pause"},{name:'type', val: "checkbox"},{name:'value', val: "true"},{name:'checked', val: shadow.pause}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n374 = h('input', props, n374Child)
      n373Child.push(n374)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n373 = h('div', props, n373Child)
      n372Child.push(n373)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n372 = h('div', props, n372Child)
      n371Child.push(n372)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n371 = h('div', props, n371Child)
      n368Child.push(n371)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "pause"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n368 = h('div', props, n368Child)
      n0Child.push(n368)
      var n375Child = []
      var n376Child = []
      var n377Child = []
      n377Child.push("播放位置")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n377 = h('label', props, n377Child)
      n376Child.push(n377)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n376 = h('div', props, n376Child)
      n375Child.push(n376)
      var n378Child = []
      var n379Child = []
      var n380Child = []
      var n381Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: "currentTime"},{name:'type', val: "number"},{name:'value', val: shadow.currentTime}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n381 = h('input', props, n381Child)
      n380Child.push(n381)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n380 = h('div', props, n380Child)
      n379Child.push(n380)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n379 = h('div', props, n379Child)
      n378Child.push(n379)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n378 = h('div', props, n378Child)
      n375Child.push(n378)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'},{name:'key', val: "currentTime"}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n375 = h('div', props, n375Child)
      n0Child.push(n375)
      break;
    default:
      var n382Child = []
      n382Child.push("不支援的類型")
      var props = {attributes: runtime.compileAttrs([], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n382 = h('p', props, n382Child)
      n0Child.push(n382)
      break;
  }
  if(shadow.type == "map" || shadow.type == "video") {
    var n383Child = []
    var n384Child = []
    n384Child.push("圖層")
    var props = {attributes: runtime.compileAttrs([], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n384 = h('b', props, n384Child)
    n383Child.push(n384)
    var n385Child = []
    var n386Child = []
    var n387Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-plus'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n387 = h('i', props, n387Child)
    n386Child.push(n387)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'},{name:'class', val: 'is-small'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n386 = h('span', props, n386Child)
    n385Child.push(n386)
    var n388Child = []
    n388Child.push("新增")
    var props = {attributes: runtime.compileAttrs([], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n388 = h('span', props, n388Child)
    n385Child.push(n388)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-primary'},{name:'onclick', val: this.addImageOverlay},{name:'type', val: "button"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n385 = h('button', props, n385Child)
    n383Child.push(n385)
    var v389 = shadow.imageOverlays
    Object.keys(v389).forEach(function (index) {
      var imageOverlay = v389[index]
      var n390Child = []
      var n391Child = []
      n391Child.push("刪除")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'class', val: 'is-danger'},{name:'type', val: "button"},{name:'onclick', val: this.deleteImageOverlay.bind(this, imageOverlay)}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n391 = h('button', props, n391Child)
      n390Child.push(n391)
      var n392Child = []
      var n393Child = []
      var n394Child = []
      n394Child.push("識別符")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n394 = h('label', props, n394Child)
      n393Child.push(n394)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n393 = h('div', props, n393Child)
      n392Child.push(n393)
      var n395Child = []
      var n396Child = []
      var n397Child = []
      var n398Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: `imageOverlays[${index}][id]`},{name:'value', val: imageOverlay.id}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n398 = h('input', props, n398Child)
      n397Child.push(n398)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n397 = h('div', props, n397Child)
      n396Child.push(n397)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n396 = h('div', props, n396Child)
      n395Child.push(n396)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n395 = h('div', props, n395Child)
      n392Child.push(n395)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n392 = h('div', props, n392Child)
      n390Child.push(n392)
      var n399Child = []
      var n400Child = []
      var n401Child = []
      n401Child.push("上")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n401 = h('label', props, n401Child)
      n400Child.push(n401)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n400 = h('div', props, n400Child)
      n399Child.push(n400)
      var n402Child = []
      var n403Child = []
      var n404Child = []
      var n405Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: `imageOverlays[${index}][top]`},{name:'type', val: "number"},{name:'value', val: imageOverlay.top}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n405 = h('input', props, n405Child)
      n404Child.push(n405)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n404 = h('div', props, n404Child)
      n403Child.push(n404)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n403 = h('div', props, n403Child)
      n402Child.push(n403)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n402 = h('div', props, n402Child)
      n399Child.push(n402)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n399 = h('div', props, n399Child)
      n390Child.push(n399)
      var n406Child = []
      var n407Child = []
      var n408Child = []
      n408Child.push("下")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n408 = h('label', props, n408Child)
      n407Child.push(n408)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n407 = h('div', props, n407Child)
      n406Child.push(n407)
      var n409Child = []
      var n410Child = []
      var n411Child = []
      var n412Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: `imageOverlays[${index}][bottom]`},{name:'type', val: "number"},{name:'value', val: imageOverlay.bottom}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n412 = h('input', props, n412Child)
      n411Child.push(n412)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n411 = h('div', props, n411Child)
      n410Child.push(n411)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n410 = h('div', props, n410Child)
      n409Child.push(n410)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n409 = h('div', props, n409Child)
      n406Child.push(n409)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n406 = h('div', props, n406Child)
      n390Child.push(n406)
      var n413Child = []
      var n414Child = []
      var n415Child = []
      n415Child.push("左")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n415 = h('label', props, n415Child)
      n414Child.push(n415)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n414 = h('div', props, n414Child)
      n413Child.push(n414)
      var n416Child = []
      var n417Child = []
      var n418Child = []
      var n419Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: `imageOverlays[${index}][left]`},{name:'type', val: "number"},{name:'value', val: imageOverlay.left}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n419 = h('input', props, n419Child)
      n418Child.push(n419)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n418 = h('div', props, n418Child)
      n417Child.push(n418)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n417 = h('div', props, n417Child)
      n416Child.push(n417)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n416 = h('div', props, n416Child)
      n413Child.push(n416)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n413 = h('div', props, n413Child)
      n390Child.push(n413)
      var n420Child = []
      var n421Child = []
      var n422Child = []
      n422Child.push("右")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n422 = h('label', props, n422Child)
      n421Child.push(n422)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n421 = h('div', props, n421Child)
      n420Child.push(n421)
      var n423Child = []
      var n424Child = []
      var n425Child = []
      var n426Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: `imageOverlays[${index}][right]`},{name:'type', val: "number"},{name:'value', val: imageOverlay.right}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n426 = h('input', props, n426Child)
      n425Child.push(n426)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n425 = h('div', props, n425Child)
      n424Child.push(n425)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n424 = h('div', props, n424Child)
      n423Child.push(n424)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n423 = h('div', props, n423Child)
      n420Child.push(n423)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n420 = h('div', props, n420Child)
      n390Child.push(n420)
      var n427Child = []
      var n428Child = []
      var n429Child = []
      n429Child.push("資源ID")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n429 = h('label', props, n429Child)
      n428Child.push(n429)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n428 = h('div', props, n428Child)
      n427Child.push(n428)
      var n430Child = []
      var n431Child = []
      var n432Child = []
      var n433Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: `imageOverlays[${index}][assetId]`},{name:'value', val: imageOverlay.assetId}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n433 = h('input', props, n433Child)
      n432Child.push(n433)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n432 = h('div', props, n432Child)
      n431Child.push(n432)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n431 = h('div', props, n431Child)
      n430Child.push(n431)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n430 = h('div', props, n430Child)
      n427Child.push(n430)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n427 = h('div', props, n427Child)
      n390Child.push(n427)
      var n434Child = []
      var n435Child = []
      var n436Child = []
      n436Child.push("按下時資源ID")
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n436 = h('label', props, n436Child)
      n435Child.push(n436)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'},{name:'class', val: 'is-normal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n435 = h('div', props, n435Child)
      n434Child.push(n435)
      var n437Child = []
      var n438Child = []
      var n439Child = []
      var n440Child = []
      var props = {attributes: runtime.compileAttrs([{name:'name', val: `imageOverlays[${index}][activeAssetId]`},{name:'value', val: imageOverlay.activeAssetId}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n440 = h('input', props, n440Child)
      n439Child.push(n440)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n439 = h('div', props, n439Child)
      n438Child.push(n439)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n438 = h('div', props, n438Child)
      n437Child.push(n438)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n437 = h('div', props, n437Child)
      n434Child.push(n437)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n434 = h('div', props, n434Child)
      n390Child.push(n434)
      var n441Child = []
      var props = {attributes: runtime.compileAttrs([], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n441 = h('hr', props, n441Child)
      n390Child.push(n441)
      var props = {attributes: runtime.compileAttrs([{name:'class', val: 'image-overlay'},{name:'key', val: imageOverlay}], [])};
      if (props.attributes.id) props.key = props.attributes.id;
      var n390 = h('div', props, n390Child)
      n383Child.push(n390)
    }.bind(this))
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'image-overlays'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n383 = h('div', props, n383Child)
    n0Child.push(n383)
  }
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  