import Base from './base'
import renderFields from './timer.pug'

export default class Timer extends Base
  @label: "等待時間"

  @DEFAULTS:
    seconds: 0

  renderFields: renderFields
