import Base from './base'
import renderFields from './fake-call.pug'

export default class FakeCall extends Base
  @label: "假電話"

  @DEFAULTS:
    callerName: "未知"
    callerNumber: "0000"
    instanceId: null
    ring: # drop instanceId temp
      assetId: null
      pause: false,
      currentTime: 0
      fadeInSeconds: 0
      fadeOutSeconds: 0
      volume: 1.0
      repeat: 1
    call: # also instanceId
      assetId: null
      pause: false,
      currentTime: 0
      fadeInSeconds: 0
      fadeOutSeconds: 0
      volume: 1.0
      repeat: 1

  renderFields: renderFields
