import Base from './base'
import renderFields from './unflag.pug'

export default class Unflag extends Base
  @label: "取消全域狀態"

  @DEFAULTS:
    names: []

  renderFields: renderFields

  onSave: (newData)->
    newData.names = newData.names.filter((s) => s.length)
