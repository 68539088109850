
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n1Child = []
  var n2Child = []
  n2Child.push("未使用場景")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'text'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n2 = h('div', props, n2Child)
  n1Child.push(n2)
  var n3Child = []
  n3Child.push("全刪")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-danger'},{name:'class', val: 'is-small'},{name:'type', val: "button"},{name:'onclick', val: this.promptDeleteAllUnusedScenes}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n3 = h('button', props, n3Child)
  n1Child.push(n3)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'is-flex'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n1 = h('header', props, n1Child)
  n0Child.push(n1)
  var n4Child = []
  var v5 = this.unusedScenes
  Object.keys(v5).forEach(function (k6) {
    var scene = v5[k6]
    var n7Child = []
    var n8Child = []
    var n9Child = []
    n9Child = n9Child.concat(scene._name || "(未命名)")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'card-header-title'},{name:'class', val: 'name'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n9 = h('div', props, n9Child)
    n8Child.push(n9)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'card-header'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n8 = h('header', props, n8Child)
    n7Child.push(n8)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'scene'},{name:'class', val: 'card'},{name:'onclick', val: this._sceneClicked.bind(this, scene)},{name:'key', val: scene}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n7 = h('div', props, n7Child)
    n4Child.push(n7)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'scene-list'},{name:'class', val: 'is-flex'},{name:'class', val: 'is-flex-wrap-wrap'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n4 = h('div', props, n4Child)
  n0Child.push(n4)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  