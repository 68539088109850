import "core-js/stable"
import "regenerator-runtime/runtime"

window.Chito =
  fetch: (url, options = {})->
    Object.assign(
      options.headers || {},
      { 'X-CSRF-Token': document.head.querySelector("[name='csrf-token']").content },
      options.headers || {}
    )

    options.credentials ?= "same-origin"

    fetch(
      url,
      options
    )

window.at = (controller, action, prefix = "") ->
  if controller.indexOf("#") != -1
    matches = controller.match(/(.*)#(.*)/)
    controller = matches[1]
    action = matches[2]

  bodyData = document.body.dataset
  currentController = bodyData.controller
  controller = "#{prefix}#{controller}"

  currentController == controller &&
    (typeof action == "undefined" || action == bodyData.action)

window.randomDomId = (prefix)->
  prefix + "-" + (Math.floor(Math.random() * Math.pow(36, 6))).toString(36)

Function::property = (name, description)->
  Object.defineProperty @prototype, name, description
