import Base from './base'
import renderFields from './step-count-exceed.pug'

export default class StepCountExceed extends Base
  @label: "場景步數"

  @DEFAULTS:
    count: 0

  renderFields: renderFields
