import Base from './base'
import renderFields from './audio.pug'

export default class Audio extends Base
  @label: "聲音"

  @DEFAULTS:
    tracks: []
    method: "update"

  renderFields: renderFields

  addTrack: =>
    @shadow.tracks.push(
      assetId: null
      instanceId: null
      volume: 1.0
      repeat: 1
    )

  deleteTrack: (track)=>
    return if (index = @shadow.tracks.indexOf(track)) == -1

    @shadow.tracks.splice(index, 1)

  setTrackRepeat: (track, repeat)=> track.repeat = repeat

  load: =>
    for track in @shadow.tracks
      continue unless typeof track.volume == "object"

      track.volume.distanceVolumes = JSON.stringify(track.volume.distanceVolumes)

    super()

  onSave: (newData)=>
    for track in newData.tracks
      continue unless typeof track.volume == "object"

      try
        track.volume.distanceVolumes = JSON.parse(track.volume.distanceVolumes)
      catch
        track.volume.distanceVolumes = []
