import { h, createProjector } from 'maquette'

import render from './meta-editor.pug'

export default class MetaEditor
  projector: createProjector()

  constructor: (@data)->

  show: =>
    document.documentElement.classList.add "is-clipped"
    @projector.append(document.body, @_render)

  bindForm: (@form)=>

  _render: =>
    render.call(this)[0]

  dismiss: =>
    await @save()
    location.reload()

  save: =>
    await fetch(
      "/playbooks/#{@data.id}",
      method: "PATCH",
      credentials: 'same-origin',
      headers:
        'X-CSRF-Token': document.head.querySelector("[name='csrf-token']").content
      body: new FormData(@form)
    )
