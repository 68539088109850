
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n502Child = []
  var n503Child = []
  n503Child.push("識別標籤")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n503 = h('label', props, n503Child)
  n502Child.push(n503)
  var n504Child = []
  var n505Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "instanceId"},{name:'value', val: shadow.instanceId},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n505 = h('input', props, n505Child)
  n504Child.push(n505)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n504 = h('div', props, n504Child)
  n502Child.push(n504)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n502 = h('div', props, n502Child)
  n0Child.push(n502)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  