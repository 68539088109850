import { createProjector } from 'maquette'

import Base from './base'
import renderDisplay from './display.pug'
import htmlTemplates from './html-templates'
import TRIGGERS from '../triggers'

export default class Display extends Base
  @DEFAULTS:
    type: "webview"

  @HTML_TEMPLATES = htmlTemplates

  @label: "顯示頁面"

  @property "template", get: ->
    htmlTemplates[@shadow._template]

  displayHtml: ""
  iframeDirty: false

  load: =>
    if @shadow.type == "map" && @shadow.styleJSON
      @shadow.styleJSON = JSON.stringify(@shadow.styleJSON)

    if (displayAsset = @assetsManager.assetFor(@store))
      @displayHtml = @shadow.displayHtml = await displayAsset.text()
      @iframeDirty = true

  templateData: (key)->
    return unless @template
    return "" unless @shadow._templateData
    return @shadow._templateData[key] if key of @shadow._templateData

    @shadow._templateData[key] ?=
      if key of @template.domIdFields
        randomDomId(@template.domIdFields[key].type)
      else
        ""

  renderFields: renderDisplay

  addPositionMarker: =>
    @shadow.positionMarkers.push({})

  addImageOverlay: =>
    @shadow.imageOverlays.push({})

  deletePositionMarker: (positionMarker)=>
    return if (index = @shadow.positionMarkers.indexOf(positionMarker)) == -1

    @shadow.positionMarkers.splice(index, 1)

  deleteImageOverlay: (imageOverlay)=>
    return if (index = @shadow.imageOverlays.indexOf(imageOverlay)) == -1

    @shadow.imageOverlays.splice(index, 1)

  render: ->
    if @shadow.type == "map" || @shadow.type == "video"
      @shadow.imageOverlays ||= []

    if @shadow.type == "map"
      @shadow.currentLocationMarker ||= {assetId: '', size: 0}
      @shadow.positionMarkers ||= []

    super()

  updatePreviewFrame: (newIframe) =>
    return unless newIframe != @iframe || @iframeDirty

    @iframe = newIframe

    doc = document.createElement('html')

    # this will be called before asset read
    doc.innerHTML = @shadow.displayHtml || ""

    base = document.createElement("base")
    base.href = "#{window.location.origin}/playbooks/#{@sceneEditor.playbookEditor.playbookId}/assets/"
    doc.querySelector("head").prepend(base)

    blob = new Blob([doc.innerHTML], type: "text/html")
    @iframe.src = URL.createObjectURL(blob)

    @iframeDirty = false

  onInput: =>
    oldHtml = @shadow.displayHtml

    super arguments...

    if this.template
      @shadow._templateData ||= {}
      @shadow.displayHtml = @template.render.call(this, @shadow._templateData)

    @iframeDirty = oldHtml != @shadow.displayHtml

  onSave: (newData)=>
    switch @shadow.type
      when "webview"
        if newData.displayHtml != @displayHtml
          @assetsManager.future(
            @store,
            content: newData.displayHtml,
            contentType: "text/html",
            memo: "場景網頁",
            autoDelete: true
          )
        delete newData.styleJSON

      when "map"
        try
          newData.styleJSON = JSON.parse(newData.styleJSON)

      else
        delete newData.styleJSON

    delete newData.displayHtml

  editClickTrigger: (domIdField)=>
    domId = @templateData(domIdField)
    @_editRelatedTrigger(domIdField, 'webviewClick', domId)

  addInputResponse: (domIdField)=>
    return unless (value = prompt("輸入值"))

    domId = @templateData(domIdField)
    @_editRelatedTrigger(domIdField, 'webviewScript', "#{domId}-#{value}", " #{value}")

  # triggerId: id means domid for click, arbitrary id for script
  _editRelatedTrigger: (domIdField,triggerType, triggerId, triggerNameSuffix="")=>
    relatedTrigger = null

    for trigger in @sceneEditor.scene.triggers
      continue unless trigger.type == triggerType && trigger.id == triggerId

      relatedTrigger = trigger
      break

    unless relatedTrigger
      relatedTrigger = TRIGGERS[triggerType].scaffold()
      relatedTrigger._name = @templateData(@template.domIdFields[domIdField].triggerNameField) + triggerNameSuffix
      relatedTrigger.id = triggerId
      @sceneEditor.scene.triggers.push(relatedTrigger)

    @sceneEditor.selectTrigger(relatedTrigger)
