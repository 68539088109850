import Base from './base'
import renderFields from './finish-playbook.pug'

export default class FinishPlaybook extends Base
  @label: "結束劇本"

  @DEFAULTS:
    names: []

  renderFields: renderFields

