import Base from './base'
import renderFields from './disable-playbook.pug'

export default class DisablePlaybook extends Base
  @label: "停用劇本"

  @DEFAULTS:
    message: ""
    retryMessage: ""

  renderFields: renderFields
