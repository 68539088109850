import WebviewClick  from './webview-click'
import ImageOverlayClick from './image-overlay-click'
import AudioFinish from './audio-finish'
import VideoFinish from './video-finish'
import Gps from './gps'
import Timer from './timer'
import WebviewScript from './webview-script'
import NotificationClick from './notification-click'
import Qrcode from './qrcode'
import FlagMatch from './flag-match'
import Beacon from './beacon'
import Wifi from './wifi'
import FakeCallDeclined from './fake-call-declined'
import PushEvent from './push-event'
import StepCountExceed from './step-count-exceed'

triggers = {}

for triggerTypeClass in [
  WebviewClick, ImageOverlayClick, AudioFinish, VideoFinish, Gps, Timer, WebviewScript,
  NotificationClick, Qrcode, FlagMatch, Beacon, Wifi, FakeCallDeclined, PushEvent, StepCountExceed
]
  className = triggerTypeClass.name
  typeName = className.charAt(0).toLowerCase() + className.slice(1)

  triggers[typeName] = triggerTypeClass

export default triggers
