import { cloneDeep, merge } from 'lodash-es'
import { eachDeep } from 'deepdash-es/standalone'

export UNSET = Symbol()

export mergeWithUnset = (object, ...assigns)->
  eachDeep(
    merge(object, ...assigns)
    (value, key, parent) -> delete parent[key] if value == UNSET
  )

export futureMerge = (object, assign)->
  -> mergeWithUnset(object, assign)

export default class ObjectEditor
  @DEFAULTS = {}

  Object.defineProperty this, "type", get: ->
    name = @name
    name.charAt(0).toLowerCase() + name.slice(1)

  # user can't edit until required assets are loaded
  readyToEdit: false

  @property "assetsManager", get: -> @sceneEditor.assetsManager
  @property "playbookEditor", get: -> @sceneEditor.playbookEditor
  @property "projector", get: -> @sceneEditor.projector

  constructor: (@store, {@sceneEditor} = {})->
    # shadow is the data binded to the form without type cast
    # this is only read by input tags, not used to saving data
    @shadow = Object.assign(
      cloneDeep(@constructor.DEFAULTS),
      cloneDeep(@store)
    )

    # based on @shadow, but type casted
    @typeCastedShadow = cloneDeep(@shadow)

    @load().then =>
      @readyToEdit = true
      @projector.scheduleRender()

  # Returns a promise to load required assets before user can start editing
  load: =>
    new Promise (resolve, _reject)=> resolve(true)

  bindForm: (@form) =>

  onInput: => @_readForm()

  # called by render(), container for renderFields()
  renderForm: -> throw "not implemented"

  renderLocals: =>
    shadow: @shadow,
    futureMerge: futureMerge,
    UNSET: UNSET

  # called by render()
  # child class can point this to pug render function
  renderFields: -> []

  render: (children=@renderFields.call(this, @renderLocals()))->
    @renderForm.call(
      this,
      Object.assign(@renderLocals(), children: children)
    )


  onSave: (_newData) =>

  save: =>
    @_readForm()

    dataToSave = cloneDeep(@typeCastedShadow)
    @onSave(dataToSave)

    # Object.assign to allow array reduce members
    mergeWithUnset(Object.assign(@store, dataToSave))

  _readForm: =>
    raw = {}
    casted = {}

    # FIXME: input group
    for input from @form.elements
      continue unless input.name

      path = [
        input.name.match(/^[^\[]+/)[0],
        ...Array.from(input.name.matchAll(/\[(.*?)\]/g)).map((match)=> match[1])
      ]

      value =
        if input.value == "true" && input.type == "checkbox"
          input.checked
        else
          input.value

      @_setObjectByPath(raw, path, value)

      value =
        if input.type in ["", "text", "number"] && input.value == ''
          UNSET
        else if input.type == "number"
          Number(value)
        else
          value

      @_setObjectByPath(casted, path, value)

    merge(@shadow, raw)
    @typeCastedShadow = merge(cloneDeep(@shadow), casted)

  # FIXME: shitty impl.
  _setObjectByPath: (store, path, value) =>
    path = path.slice()
    key = null

    while path.length
      key = path.shift()
      break unless key of store
      store = store[key]

    while path.length
      nextKey = path.shift()

      if nextKey.match(/^\d+$/) || nextKey == ""
        store = (store[key] = [])
      else
        store = (store[key] = {})

      key = nextKey

    if key
      store[key] = value
    else
      store.push(value)

