
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n715Child = []
  var n716Child = []
  var n717Child = []
  n717Child.push("狀態名稱")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n717 = h('label', props, n717Child)
  n716Child.push(n717)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n716 = h('div', props, n716Child)
  n715Child.push(n716)
  var n718Child = []
  if(shadow.names.indexOf("") == -1) {
    shadow.names.push("")
  }
  var v719 = shadow.names
  Object.keys(v719).forEach(function (index) {
    var value = v719[index]
    var n720Child = []
    var n721Child = []
    var n722Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'type', val: "text"},{name:'name', val: `names[${index}]`},{name:'value', val: value}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n722 = h('input', props, n722Child)
    n721Child.push(n722)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n721 = h('div', props, n721Child)
    n720Child.push(n721)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'key', val: `names[${index}]`}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n720 = h('div', props, n720Child)
    n718Child.push(n720)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n718 = h('div', props, n718Child)
  n715Child.push(n718)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n715 = h('div', props, n715Child)
  n0Child.push(n715)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  