
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n758Child = []
  var n759Child = []
  var n760Child = []
  n760Child.push("識別標籤")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n760 = h('label', props, n760Child)
  n759Child.push(n760)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n759 = h('div', props, n759Child)
  n758Child.push(n759)
  var n761Child = []
  var n762Child = []
  var n763Child = []
  var n764Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "instanceId"},{name:'value', val: shadow.instanceId},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n764 = h('input', props, n764Child)
  n763Child.push(n764)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n763 = h('div', props, n763Child)
  n762Child.push(n763)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n762 = h('div', props, n762Child)
  n761Child.push(n762)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n761 = h('div', props, n761Child)
  n758Child.push(n761)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n758 = h('div', props, n758Child)
  n0Child.push(n758)
  var n765Child = []
  var n766Child = []
  var n767Child = []
  n767Child.push("來電名稱")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n767 = h('label', props, n767Child)
  n766Child.push(n767)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n766 = h('div', props, n766Child)
  n765Child.push(n766)
  var n768Child = []
  var n769Child = []
  var n770Child = []
  var n771Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "callerName"},{name:'value', val: shadow.callerName},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n771 = h('input', props, n771Child)
  n770Child.push(n771)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n770 = h('div', props, n770Child)
  n769Child.push(n770)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n769 = h('div', props, n769Child)
  n768Child.push(n769)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n768 = h('div', props, n768Child)
  n765Child.push(n768)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n765 = h('div', props, n765Child)
  n0Child.push(n765)
  var n772Child = []
  var n773Child = []
  var n774Child = []
  n774Child.push("來電號碼")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n774 = h('label', props, n774Child)
  n773Child.push(n774)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n773 = h('div', props, n773Child)
  n772Child.push(n773)
  var n775Child = []
  var n776Child = []
  var n777Child = []
  var n778Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "callerNumber"},{name:'value', val: shadow.callerNumber},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n778 = h('input', props, n778Child)
  n777Child.push(n778)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n777 = h('div', props, n777Child)
  n776Child.push(n777)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n776 = h('div', props, n776Child)
  n775Child.push(n776)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n775 = h('div', props, n775Child)
  n772Child.push(n775)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n772 = h('div', props, n772Child)
  n0Child.push(n772)
  var n779Child = []
  var n780Child = []
  var n781Child = []
  n781Child.push("鈴聲")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n781 = h('label', props, n781Child)
  n780Child.push(n781)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n780 = h('div', props, n780Child)
  n779Child.push(n780)
  var n782Child = []
  var n783Child = []
  var n784Child = []
  var n785Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "ring[assetId]"},{name:'value', val: shadow.ring.assetId},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n785 = h('input', props, n785Child)
  n784Child.push(n785)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n784 = h('div', props, n784Child)
  n783Child.push(n784)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n783 = h('div', props, n783Child)
  n782Child.push(n783)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n782 = h('div', props, n782Child)
  n779Child.push(n782)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n779 = h('div', props, n779Child)
  n0Child.push(n779)
  var n786Child = []
  var n787Child = []
  var n788Child = []
  n788Child.push("電話內容")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n788 = h('label', props, n788Child)
  n787Child.push(n788)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n787 = h('div', props, n787Child)
  n786Child.push(n787)
  var n789Child = []
  var n790Child = []
  var n791Child = []
  var n792Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "call[assetId]"},{name:'value', val: shadow.call.assetId},{name:'type', val: "text"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n792 = h('input', props, n792Child)
  n791Child.push(n792)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n791 = h('div', props, n791Child)
  n790Child.push(n791)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n790 = h('div', props, n790Child)
  n789Child.push(n790)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n789 = h('div', props, n789Child)
  n786Child.push(n789)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'},{name:'class', val: 'is-horizontal'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n786 = h('div', props, n786Child)
  n0Child.push(n786)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  