
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n186Child = []
  var n187Child = []
  var n188Child = []
  n188Child.push("資源")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n188 = h('span', props, n188Child)
  n187Child.push(n188)
  var n189Child = []
  var n190Child = []
  var n191Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'fas'},{name:'class', val: 'fa-plus'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n191 = h('i', props, n191Child)
  n190Child.push(n191)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'icon'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n190 = h('span', props, n190Child)
  n189Child.push(n190)
  var n192Child = []
  n192Child.push("新增")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n192 = h('span', props, n192Child)
  n189Child.push(n192)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'button'},{name:'class', val: 'is-small'},{name:'type', val: "button"},{name:'onclick', val: this.onAddClick}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n189 = h('button', props, n189Child)
  n187Child.push(n189)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'panel-heading'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n187 = h('div', props, n187Child)
  n186Child.push(n187)
  var v193 = [...this.assets.values()]
  Object.keys(v193).forEach(function (k194) {
    var asset = v193[k194]
    var n195Child = []
    var n196Child = []
    n196Child = n196Child.concat(asset.id.toString())
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'asset-id'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n196 = h('div', props, n196Child)
    n195Child.push(n196)
    var n197Child = []
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'asset-memo'},{name:'class', val: 'input'},{name:'class', val: 'is-small'},{name:'value', val: asset.memo},{name:'oninput', val: this.onMemoInput.bind(this, asset)},{name:'type', val: "text"},{name:'placeholder', val: "備註"}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n197 = h('input', props, n197Child)
    n195Child.push(n197)
    var n198Child = []
    var n199Child = []
    var props = {attributes: runtime.compileAttrs([{name:'type', val: "checkbox"},{name:'checked', val: asset.autoDelete},{name:'oninput', val: this.onAutoDeleteInput.bind(this, asset)}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n199 = h('input', props, n199Child)
    n198Child.push(n199)
    n198Child.push("允許自動刪除")
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'checkbox'}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n198 = h('label', props, n198Child)
    n195Child.push(n198)
    var props = {attributes: runtime.compileAttrs([{name:'class', val: 'panel-block'},{name:'class', val: 'asset'},{name:'id', val: `assets-manager-${asset.id}`}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n195 = h('div', props, n195Child)
    n186Child.push(n195)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'assets'},{name:'class', val: 'panel'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n186 = h('section', props, n186Child)
  n0Child.push(n186)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  