import Display from './display'
import Audio from './audio'
import GotoScene from './goto-scene'
import Flag from './flag'
import Unflag from './unflag'
import FakeCall from './fake-call'
import Notification from './notification'
import QrScan from './qr-scan'
import ChangePlaybookThumbnail from './change-playbook-thumbnail'
import DisablePlaybook from './disable-playbook'
import FinishPlaybook from './finish-playbook'
import EnableTorch from './enable-torch'
import DisableTorch from './disable-torch'

export INVALID_COMBINATIONS = [
  ["display", "gotoScene", "finishPlaybook", "fakeCall", "qrScan"],
  ["audio", "fakeCall"],
  ["enableTorch", "disableTorch"]
]

# return conflicting actionType from currentActions if user want to add newAction
export checkConflict = (currentActions, newAction)->
  for combination in INVALID_COMBINATIONS
    continue if combination.indexOf(newAction) == -1

    for action in currentActions
      return action if combination.indexOf(action) != -1

  null

actions = {}

for actionTypeClass in [
  Display, Audio, GotoScene, Flag, Unflag, FakeCall, Notification, QrScan,
  ChangePlaybookThumbnail, DisablePlaybook, FinishPlaybook, EnableTorch, DisableTorch
]
  className = actionTypeClass.name
  typeName = className.charAt(0).toLowerCase() + className.slice(1)

  actions[typeName] = actionTypeClass

export default actions
