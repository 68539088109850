import { h, createProjector } from 'maquette'
import moment from 'moment'
import qs from 'qs'

import render from './playable-periods-editor.pug'

unixEpochToInputValues = (unix)=>
  return { date: null, time: null } unless unix

  datetime = moment.unix(unix)

  { date: datetime.format("YYYY-MM-DD"), time: datetime.format("HH:mm:ss") }

inputValuesToUnixEpoch = (inputValues)=>
  return null unless inputValues.date || inputValues.time

  unix = 0
  unix += moment(inputValues.date, 'YYYY-MM-DD').unix() if inputValues.date
  unix += moment(inputValues.time, 'HH:mm').unix() - moment().startOf('day').unix() if inputValues.time

  unix

export default class PlayablePeriodsEditor
  projector: createProjector()

  constructor: (@playbook)->
    @shadow = @playbook.playablePeriods?.map (period)->
      [start, end] = period || []

      { start: unixEpochToInputValues(start), end: unixEpochToInputValues(end) }

    @shadow ||= []

  show: =>
    document.documentElement.classList.add "is-clipped"
    @projector.append(document.body, @_render)

  bindForm: (@form)=>

  emptyRow: =>
    start: unixEpochToInputValues(null), end: unixEpochToInputValues(null)

  onInput: (e)=>
    formObj = qs.parse(new URLSearchParams(new FormData(e.target.form)).toString(), arrayLimit: 999)

    @shadow = formObj.periods.filter (row)=>
      inputValuesToUnixEpoch(row.start) || inputValuesToUnixEpoch(row.end)

  _render: =>
    render.call(this)[0]

  dismiss: =>
    await @save()

    projection = @projector.detach(@_render)
    projection.domNode.remove()
    document.documentElement.classList.remove "is-clipped"

  save: =>
    if @shadow.length
      @playbook.playablePeriods = @shadow.map (row)=>
        [inputValuesToUnixEpoch(row.start), inputValuesToUnixEpoch(row.end)]
    else
      @playbook.playablePeriods = null

    await @playbook.save()
