
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n102Child = []
  var n103Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-background'},{name:'onclick', val: this.dismiss}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n103 = h('div', props, n103Child)
  n102Child.push(n103)
  var n104Child = []
  var n105Child = []
  var n106Child = []
  n106Child.push("開放時間")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-title'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n106 = h('p', props, n106Child)
  n105Child.push(n106)
  var n107Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'delete'},{name:'onclick', val: this.dismiss},{name:'type', val: "button"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n107 = h('button', props, n107Child)
  n105Child.push(n107)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-head'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n105 = h('header', props, n105Child)
  n104Child.push(n105)
  var n108Child = []
  var n109Child = []
  var n110Child = []
  var n111Child = []
  var n112Child = []
  n112Child.push("開始時間")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n112 = h('th', props, n112Child)
  n111Child.push(n112)
  var n113Child = []
  n113Child.push("結束時間")
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n113 = h('th', props, n113Child)
  n111Child.push(n113)
  var props = {attributes: runtime.compileAttrs([], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n111 = h('tr', props, n111Child)
  n110Child.push(n111)
  var v114 = [].concat(this.shadow).concat([this.emptyRow()])
  Object.keys(v114).forEach(function (i) {
    var period = v114[i]
    var n115Child = []
    var n116Child = []
    var n117Child = []
    var props = {attributes: runtime.compileAttrs([{name:'name', val: `periods[${i}][start][date]`},{name:'type', val: "date"},{name:'value', val: period.start.date}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n117 = h('input', props, n117Child)
    n116Child.push(n117)
    var n118Child = []
    var props = {attributes: runtime.compileAttrs([{name:'name', val: `periods[${i}][start][time]`},{name:'type', val: "time"},{name:'value', val: period.start.time}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n118 = h('input', props, n118Child)
    n116Child.push(n118)
    var props = {attributes: runtime.compileAttrs([], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n116 = h('td', props, n116Child)
    n115Child.push(n116)
    var n119Child = []
    var n120Child = []
    var props = {attributes: runtime.compileAttrs([{name:'name', val: `periods[${i}][end][date]`},{name:'type', val: "date"},{name:'value', val: period.end.date}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n120 = h('input', props, n120Child)
    n119Child.push(n120)
    var n121Child = []
    var props = {attributes: runtime.compileAttrs([{name:'name', val: `periods[${i}][end][time]`},{name:'type', val: "time"},{name:'value', val: period.end.time}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n121 = h('input', props, n121Child)
    n119Child.push(n121)
    var props = {attributes: runtime.compileAttrs([], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n119 = h('td', props, n119Child)
    n115Child.push(n119)
    var props = {attributes: runtime.compileAttrs([{name:'key', val: `period-${i}`}], [])};
    if (props.attributes.id) props.key = props.attributes.id;
    var n115 = h('tr', props, n115Child)
    n110Child.push(n115)
  }.bind(this))
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'table'},{name:'class', val: 'is-bordered'},{name:'class', val: 'is-fullwidth'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n110 = h('table', props, n110Child)
  n109Child.push(n110)
  var props = {attributes: runtime.compileAttrs([{name:'afterCreate', val: this.bindForm},{name:'afterUpdate', val: this.bindForm},{name:'oninput', val: this.onInput}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n109 = h('form', props, n109Child)
  n108Child.push(n109)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card-body'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n108 = h('section', props, n108Child)
  n104Child.push(n108)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'modal-card'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n104 = h('div', props, n104Child)
  n102Child.push(n104)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'meta-editor'},{name:'class', val: 'modal'},{name:'class', val: 'is-active'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n102 = h('div', props, n102Child)
  n0Child.push(n102)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  