
    
        var maquetteH = require('maquette').h;
        var h = function(selector, props, children) {
          var attributes = Object.assign({}, props.attributes || {}, props);
          delete attributes["attributes"];

          // This should be done by pug-vdom, oh well...
          if (attributes.class) {
            selector += "." + attributes.class.trim().split(/ +/).join(".");
            delete attributes["class"];
          }

          return maquetteH(selector, attributes, children);
        }
      ;
    function render(context, h) {
  if (!pugVDOMRuntime) throw "pug-vdom runtime not found.";
  var runtime = pugVDOMRuntime
  var locals = context;
  var self = locals;
  var remainingKeys = pugVDOMRuntime.enterLocalsScope(locals);
  for (var prop in remainingKeys) {
    eval('var ' + prop + ' =  locals.' + prop);
  }
  var n0Child = []
  var n514Child = []
  var n515Child = []
  n515Child.push("UUID")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n515 = h('label', props, n515Child)
  n514Child.push(n515)
  var n516Child = []
  var n517Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "uuid"},{name:'value', val: shadow.uuid}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n517 = h('input', props, n517Child)
  n516Child.push(n517)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n516 = h('div', props, n516Child)
  n514Child.push(n516)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'uuid'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n514 = h('div', props, n514Child)
  n0Child.push(n514)
  var n518Child = []
  var n519Child = []
  n519Child.push("Major")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n519 = h('label', props, n519Child)
  n518Child.push(n519)
  var n520Child = []
  var n521Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "major"},{name:'value', val: shadow.major},{name:'type', val: "number"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n521 = h('input', props, n521Child)
  n520Child.push(n521)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n520 = h('div', props, n520Child)
  n518Child.push(n520)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n518 = h('div', props, n518Child)
  n0Child.push(n518)
  var n522Child = []
  var n523Child = []
  n523Child.push("Minor")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n523 = h('label', props, n523Child)
  n522Child.push(n523)
  var n524Child = []
  var n525Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "minor"},{name:'value', val: shadow.minor},{name:'type', val: "number"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n525 = h('input', props, n525Child)
  n524Child.push(n525)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n524 = h('div', props, n524Child)
  n522Child.push(n524)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n522 = h('div', props, n522Child)
  n0Child.push(n522)
  var n526Child = []
  var n527Child = []
  n527Child.push("距離")
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'label'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n527 = h('label', props, n527Child)
  n526Child.push(n527)
  var n528Child = []
  var n529Child = []
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'input'},{name:'name', val: "distance"},{name:'value', val: shadow.distance},{name:'type', val: "number"},{name:'step', val: "0.01"}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n529 = h('input', props, n529Child)
  n528Child.push(n529)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'control'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n528 = h('div', props, n528Child)
  n526Child.push(n528)
  var props = {attributes: runtime.compileAttrs([{name:'class', val: 'field'}], [])};
  if (props.attributes.id) props.key = props.attributes.id;
  var n526 = h('div', props, n526Child)
  n0Child.push(n526)
  pugVDOMRuntime.exitLocalsScope()
  return n0Child
}
;
    module.exports = function(locals) {
      locals = Object.assign(window.DEFAULT_TEMPLATE_LOCALS || {}, locals || {});
      return render.call(this, locals, h);
    };
  