import { v4 as uuid } from 'uuid'

import ObjectEditor from '../object-editor'
import renderForm from './base.pug'

# base class for triggers
export default class Base extends ObjectEditor
  renderForm: renderForm

  # generate a blank object
  @scaffold: ->
    Object.assign(_ref: uuid(), type: @type, actions: {}, @defaults)

  onSave: (newData)=>
    # do not overwrite actions in the store
    delete newData.actions

  finish: =>
    @save()
    @sceneEditor.editingActiveTrigger = false
