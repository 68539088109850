import RailsUjs from '@rails/ujs'
import { h, createProjector } from 'maquette'
import { v4 as uuid } from 'uuid'
import QRCode from 'qrcode'

import AssetsManager from './assets-manager'
import MetaEditor from './meta-editor.coffee'
import PlayablePeriodsEditor from './playable-periods-editor.coffee'
import SceneEditor from './scene-editor.coffee'
import renderSceneInFlow from './scene-in-flow.pug'
import renderUnusedScenes from './unused-scenes.pug'
import renderActions from './playbook-actions.pug'

export default class PlaybookEditor
  @SPECIAL_SCENES =
    backgroundScene: "背景場景"
    landingScene: "非公開期間場景"

  assetsManager: null
  protectedWarned: false

  @init: ->
    RailsUjs.ajax(
      type: "GET",
      url: location.href,
      dataType: 'json',
      success: (data)-> new PlaybookEditor(data)
    )

  @property "usedScenes", get: ->
    used = []

    visit = (scene)=>
      used.push(scene)

      for relatedScene in @_getRelatedScenes(scene)
        continue if relatedScene in used

        visit(relatedScene)

    visit(@scenes[0])
    used

  @property "unusedScenes", get: ->
    used = @usedScenes
    unused = []

    for scene in @scenes
      continue if scene in used
      continue if scene == @backgroundScene || scene == @landingScene

      unused.push scene

    unused

  @property "pushEventTriggers", get: ->
    triggers = []

    for scene in @usedScenes.concat(@backgroundScene, @landingScene)
      continue unless scene?.triggers

      for trigger in scene.triggers
        continue unless trigger.type == "pushEvent"

        triggers.push trigger

    triggers

  constructor: (playbook)->
    @playbookId = playbook.id
    @title = playbook.title
    @playablePeriods = playbook.content.playablePeriods
    @scenes = playbook.content.scenes || []
    @protected = playbook.protected

    @scenes.push { _name: "起點", id: uuid() } unless @scenes.length

    for key, name of @constructor.SPECIAL_SCENES
      contentKey = "#{key}Id"

      if sceneId = playbook.content[contentKey]
        this[key] = @getSceneById(sceneId)
      else
        @scenes.push(
          this[key] = { _name: name, id: uuid(), initial: {}, triggers: [] }
        )

    @assetsManager = new AssetsManager(this, playbook.assets)
    @scenesFlowContainer = document.querySelector(".scenes-flow")

    document.getElementById("playbook-title").addEventListener "change", (e)=>
      @title = e.target.value
      @save()

    document.getElementById("edit-meta").addEventListener "click", =>
      new MetaEditor(Object.assign({}, playbook, title: @title)).show()

    document.getElementById("edit-play-periods").addEventListener "click", =>
      new PlayablePeriodsEditor(this).show()

    document.getElementById("qr").addEventListener "click", (e)=>
      e.preventDefault()
      e.stopImmediatePropagation()

      QRCode.toDataURL e.target.href, {}, (err, url)=>
        win = window.open()
        img = document.createElement("img")
        img.src = url
        win.document.body.append(img)

    @_setupSceneFlow()
    @_setupUnused()
    @_setupActions()

  addScene: =>
    newScene = {id: uuid(), _name: ""}
    @scenes.push newScene
    @save()
    @editScene(newScene)

  toJSON: ->
    JSON.stringify(
      title: @title
      content:
        playablePeriods: @playablePeriods
        backgroundSceneId: @backgroundScene.id
        landingSceneId: @landingScene.id
        scenes: @scenes
    )

  redraw: ->
    for projector in [@sceneFlowProjector, @unusedScenesProjector, @actionsProjector]
      projector?.scheduleRender()

  save: ->
    @redraw()

    response = await fetch(
      "/playbooks/#{@playbookId}",
      method: "PATCH",
      credentials: 'same-origin',
      headers:
        'Content-Type': 'application/json'
        'Accept': 'application/json'
        'X-CSRF-Token': document.head.querySelector("[name='csrf-token']").content
      body: @toJSON()
    )

    if not response.ok
      responseJson = await response.json()
      alert("儲存失敗:\n" + responseJson.errors?.content)


  getSceneById: (id)->
    for scene in @scenes
      return scene if id == scene.id

  editScene: (scene)->
    if @protected && !@protectedWarned
      return unless confirm("這個劇本已鎖定內容編輯，任何編輯將不會被儲存，仍要顯示場景設定?")
      @protectedWarned = true

    new SceneEditor(scene: scene, playbookEditor: this)

  editBackgroundScene: => @editScene(@backgroundScene)
  editLandingScene: => @editScene(@landingScene)

  triggerPushEvent: (pushEventId)=>
    await fetch(
      "/playbooks/#{@playbookId}/trigger_push_event",
      method: "POST",
      credentials: 'same-origin',
      headers:
        'Content-Type': 'application/json'
        'Accept': 'application/json'
        'X-CSRF-Token': document.head.querySelector("[name='csrf-token']").content
      body: JSON.stringify(push_event_id: pushEventId)
    )

  promptDeleteAllUnusedScenes: =>
    return unless confirm("確認要刪除所有未使用場景? 這個操作無法復原")

    for scene in @unusedScenes
      @scenes.splice(@scenes.indexOf(scene), 1)

    @save()

  _sceneClicked: (scene, e)-> @editScene(scene)

  _setupSceneFlow: ->
    scene = @scenes[0]

    (@sceneFlowProjector = createProjector()).append(
      @scenesFlowContainer,
      => renderSceneInFlow.call(this, scene: scene, renderSceneInFlow: renderSceneInFlow, renderred: [])[0]
    )

  _setupUnused: ->
    (@unusedScenesProjector = createProjector()).replace(
      document.querySelector("main > .unused")
      => h("div.unused", {}, renderUnusedScenes.call(this))
    )

  _setupActions: ->
    (@actionsProjector = createProjector()).replace(
      document.querySelector("main > .actions")
      => h("div.actions", {}, renderActions.call(this))
    )

  _getRelatedScenes: (scene)->
    return []  unless scene.triggers

    related = []
    for trigger in scene.triggers
      continue unless (gotoScene = trigger?.actions?.gotoScene)

      related.push(scene) if (scene = @getSceneById(gotoScene.sceneId))

    related

