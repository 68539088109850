import Base from './base'
import renderFields from './qr-scan.pug'

export default class QrScan extends Base
  @label: "掃描 QR 條碼"

  @DEFAULTS:
    instanceId: null

  renderFields: renderFields
