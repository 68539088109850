var pug = require("!../../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (backgroudAssetId, buttonId, buttonText, text) {
      pug_html = pug_html + "\u003C!DOCTYPE html\u003E\u003Chtml\u003E\u003Chead\u003E\u003Cmeta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"\u002F\u003E\u003Cstyle\u003Ehtml, body { height: 100%; }\n\nbody {\n  background-size: contain;\n  background-position: center center;\n  background-repeat: no-repeat;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  margin: 1vmin 8vmin;\n}\n\n.text {\n  background: rgba(0, 0, 0, 0.5);\n  padding: 4vmin;\n  color: white;\n  text-align: center;\n  margin: 1vh 1vw;\n  font-family: sans-serif;\n  font-size: 6vmin;\n  border-radius: 3vmin;\n}\n\n.button {\n  border-radius: 3vw;\n  min-width: 15vmin;\n  font-size: 7vmin;\n  line-height: 2;\n  display: block;\n  margin: 8vmin auto 0;\n  text-align: center;\n  background: #CB5449;\n  border: none;\n  color: white;\n}\n\u003C\u002Fstyle\u003E\u003C\u002Fhead\u003E\u003Cbody" + (pug.attr("style", pug.style(`background-image: url(${backgroudAssetId})`), true, true)) + "\u003E\u003Cdiv class=\"text\"\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cbutton" + (" class=\"button\""+" type=\"button\""+pug.attr("id", buttonId, true, true)) + "\u003E" + (pug.escape(null == (pug_interp = buttonText) ? "" : pug_interp)) + "\u003C\u002Fbutton\u003E\u003C\u002Fbody\u003E\u003C\u002Fhtml\u003E";
    }.call(this, "backgroudAssetId" in locals_for_with ?
        locals_for_with.backgroudAssetId :
        typeof backgroudAssetId !== 'undefined' ? backgroudAssetId : undefined, "buttonId" in locals_for_with ?
        locals_for_with.buttonId :
        typeof buttonId !== 'undefined' ? buttonId : undefined, "buttonText" in locals_for_with ?
        locals_for_with.buttonText :
        typeof buttonText !== 'undefined' ? buttonText : undefined, "text" in locals_for_with ?
        locals_for_with.text :
        typeof text !== 'undefined' ? text : undefined));
    ;;return pug_html;};
module.exports = template;